import * as ApiConfig from "./api";

export const TOKEN_KEY = 'token:ats:20240709';
export const LIMITCANDIDATESNUMBER = 3;
export const talentClueUrl = 'https://app.talentclue.com/es/user/login'
export const FILTER_STRING_SEPARATOR = ';'
export const MODULES = {
    ID_TALENT_ACQUISITION: 'talent_acquisition',
    ID_ON_BOARDING: 'on_board',
    ID_WELCOME_PACK: 'welcome',
    ID_EMPLOYEE_LINE: 'employee_line',
    ID_FEEDBACK: 'feedback',
    ID_ABSENTEEISM: 'abseentism',
    ID_SURVEYS: 'survey',
    ID_VISUAL_TUTOR: 'visual',
    ID_EXIT_INTERVIEW: 'exit_interview',
    currentModule: function () {
        const pieces = window.location.pathname.split('/');
        const modules = Object.values(MODULES);
        for(const piece of pieces ) if(modules.includes(piece)) return piece;
    }
}


export const MODULES_ARRAY = [
    MODULES.ID_TALENT_ACQUISITION,
    MODULES.ID_ON_BOARDING,
    MODULES.ID_WELCOME_PACK,
    MODULES.ID_EMPLOYEE_LINE,
    MODULES.ID_FEEDBACK,
    MODULES.ID_ABSENTEEISM,
    MODULES.ID_SURVEYS,
    MODULES.ID_VISUAL_TUTOR,
    MODULES.ID_EXIT_INTERVIEW,
];

export const MODULE_BY_USER_TYPE = [
    {'module': MODULES.ID_TALENT_ACQUISITION, 'type': 'technical_selection_user'},
    {'module': MODULES.ID_ON_BOARDING, 'type': 'user_on_boarding'},
    {'module': MODULES.ID_EXIT_INTERVIEW, 'type': 'user_exit_interview'},
    {'module': MODULES.ID_FEEDBACK, 'type': 'user_feedback'},
    {'module': MODULES.ID_EMPLOYEE_LINE, 'type': 'rrhh_user'},
];

export const SECTION_KEYS = {
    INBOX: 'inbox',
    PUSH_OFFER: 'push-offer',
    PUSH_OFFER_CANDIDATES: 'offer-candidatures',
    PULL_CONFIG: 'pull-config',
    SAVED_SEARCHES: 'saved-searches',
    SAVED_SEARCHES_ITEM: 'saved-searches-item',
    PLANNING: 'planning',
    FAVORITES: 'favorites',
    REPORTS: 'reports',
    STATISTICS: 'statistics',
    USER_MANUAL: 'user-manual',
    PHASES_STATUS: 'phases-status',
    EVALUATIVE_TESTS: 'evaluative-tests',
    JOB_OFFERS: 'job-offers',
    JOB_OFFER_APPLICATIONS: 'job-offer-applications',
    VACANCY_REQUESTS: 'vacancy_requests',
}

export const getSectionKeys = () => {
    return Object.values(SECTION_KEYS);
}

export const SECTION_MAPPING = {}

export function getCurrentSectionKey() {
    if(SECTION_MAPPING[window.location.pathname]) return SECTION_MAPPING[window.location.pathname];

    const pieces = window.location.pathname.split('/');
    const sections = getSectionKeys();

    for(const piece of pieces ) {
        if(sections.includes(piece)) {
            if (piece === SECTION_KEYS.SAVED_SEARCHES && pieces[pieces.length-1] !== SECTION_KEYS.SAVED_SEARCHES) {
                SECTION_MAPPING[window.location.pathname] = SECTION_KEYS.SAVED_SEARCHES_ITEM;
                return SECTION_KEYS.SAVED_SEARCHES_ITEM;
            }
            if (piece === SECTION_KEYS.JOB_OFFERS && pieces[pieces.length-1] !== SECTION_KEYS.JOB_OFFERS) {
                SECTION_MAPPING[window.location.pathname] = SECTION_KEYS.JOB_OFFER_APPLICATIONS;
                return SECTION_KEYS.JOB_OFFER_APPLICATIONS;
            }
            SECTION_MAPPING[window.location.pathname] = piece;
            return piece;
        }
    }
}

export const QUERY_KEYS = {
    INBOX: 'inbox',
    TOTAL_COUNT: 'total_count',
    FAVORITES: 'favorites',
    PROFILE: 'profile',
    PERSON: 'person',
    OBSERVATIONS: 'observations',
    PUSH_OFFER_CANDIDATURE: 'push_offer_candidature',
    FILTERS: 'filters',
    FIELDS: 'fields',
    STATUS_TYPES: 'status_types',
    FUNNEL_STATUS_TYPES: 'funnel_status_types',
    TAGS_TYPES: 'tags_types',
    REPORT_FIELDS: 'report_fields',
    SAVED_SEARCHES: 'saved-searches',
    SAVED_SEARCHES_ITEM: 'saved-searches-item',
    CITATIONS: 'citations',
    CITATION_RESPONSIBLE: 'citation_responsible',
    MEETING_DETAILS: 'meeting_details',
    PHASES: 'phases',
    EVALUATIVE_TESTS: 'evaluative-tests',
    EVALUATIVE_TEST: 'evaluative-test',
    USERS: 'users',
    USERS_FOR_FILTERS: 'users_for_filters',
    USER: 'user',
    TAGS: 'tags',
    TAG: 'tag',
    RPA_INTEGRATIONS: 'rpa_integrations',
    RPA_CANDIDATURES: 'rpa_candidatures',
    RPA_KEYWORD: 'rpa_keyword',
    DASHBOARDS: 'dashboards',
    AVAILABLE_DASHBOARDS: 'available_dashboards',
    IA_EVALUATION: 'ia_evaluation_list',
    IA_EVALUATION_ROW: 'ia_evaluation_data',
    IA_EVALUATION_CRITERIA: 'ia_evaluation_criteria',
    JOBS_BY_FAMILY: 'jobs_by_family',
    JOB_OFFERS: 'job_offers',
    JOB_OFFER_ROW: 'job_offer_data',
    JOB_OFFER_APPLICATIONS: 'job_offer_applications',
    SELFUSER: 'selfuser',
    VACANCY_REQUEST: 'vacancy_request',
    TAGS_PERSON: 'tags_person',
}

export const QUERY_ACTIONS = {
    INVALIDATE: 'invalidate',
    REFETCH: 'refetch',
    REMOVE: 'remove',
    RESET: 'reset',
    CANCEL: 'cancel',
}

export const ROLES = {
    ROLE_ATS_ADMIN_ROOT: 'ROLE_ADMIN_ROOT',
    // TA
    ROLE_ATS_ADMIN_talent_acquisition: 'ROLE_ATS_ADMIN_talent_acquisition',
    ROLE_SUPERVISOR_talent_acquisition: 'ROLE_SUPERVISOR_talent_acquisition',
    ROLE_READ_ONLY_talent_acquisition: 'ROLE_READ_ONLY_talent_acquisition',
    // OB
    ROLE_ATS_ADMIN_on_board: 'ROLE_ATS_ADMIN_on_board',
    ROLE_SUPERVISOR_on_board: 'ROLE_SUPERVISOR_on_board',
    ROLE_READ_ONLY_on_board: 'ROLE_READ_ONLY_on_board',
    // LdE
    ROLE_ATS_ADMIN_employee_line: 'ROLE_ATS_ADMIN_employee_line',
    ROLE_SUPERVISOR_employee_line: 'ROLE_SUPERVISOR_employee_line',
    ROLE_READ_ONLY_employee_line: 'ROLE_READ_ONLY_employee_line',
    // FB
    ROLE_ATS_ADMIN_feedback: 'ROLE_ATS_ADMIN_feedback',
    ROLE_SUPERVISOR_feedback: 'ROLE_SUPERVISOR_feedback',
    ROLE_READ_ONLY_feedback: 'ROLE_READ_ONLY_feedback',
    // EI
    ROLE_ATS_ADMIN_exit_interview: 'ROLE_ATS_ADMIN_exit_interview',
    ROLE_READ_ONLY_exit_interview: 'ROLE_READ_ONLY_exit_interview',
    ROLE_SUPERVISOR_exit_interview: 'ROLE_SUPERVISOR_exit_interview',
    // User Types
    ROLE_TECHNICAL_SELECTION: 'ROLE_TECHNICAL_SELECTION',
    ROLE_RRHH_USER: 'ROLE_RRHH_USER',
    ROLE_ON_BOARDING: 'ROLE_ON_BOARDING',
    ROLE_FEEDBACK_USER: 'ROLE_FEEDBACK_USER',
    ROLE_EXIT_INTERVIEW: 'ROLE_EXIT_INTERVIEW',
    // Features
    ROLE_FEATURE_push_offer: 'ROLE_FEATURE_push_offer',
    ROLE_FEATURE_phases_status: 'ROLE_FEATURE_phases_status',
    ROLE_FEATURE_pull_config: 'ROLE_FEATURE_pull_config',
    ROLE_FEATURE_doc_request: 'ROLE_FEATURE_doc_request',
    ROLE_FEATURE_edit_fields: 'ROLE_FEATURE_edit_fields',
    ROLE_FEATURE_videointerview: 'ROLE_FEATURE_videointerview',
    ROLE_FEATURE_vacancy_request: 'ROLE_FEATURE_vacancy_request',
    ROLE_FEATURE_job_offer: 'ROLE_FEATURE_job_offer',
    ROLE_FEATURE_iagen: 'ROLE_FEATURE_iagen',
}

export const INTEGRATIONS = [
    {
        name: 'outlook',
        event_type: 'Microsoft',
        logo: 'teams_logo.png',
        calendar_logo: 'outlook_logo.svg',
        url: `${ApiConfig.BASE_URL}/auth_microsoft`
    },
    {
        name: 'google',
        event_type: 'Google',
        logo: 'meet_logo.png',
        calendar_logo: 'google-calendar-logo.svg',
        url: `${ApiConfig.BASE_URL}/auth_google`
    },
]

export const PHASES_STATUS_TYPES = {
    CUSTOM: 'CUSTOM',
    HIRED: 'HIRED',
    RECEIVED: 'RECEIVED',
    REGISTERED: 'REGISTERED',
}

export const USERS_ROLES = {
    ATS_ADMIN_USER: 'ATS_ADMIN',
    SUPERVISOR_USER: 'SUPERVISOR',
    READ_ONLY_USER: 'READ_ONLY',
    BASIC_USER: 'BASIC',
}

export const INTEGRATIONS_RPA = {
    INFOJOBS_PROVIDER: {
        default: 'infojobs',
        priority: 'infojobsPriority',
    },
    EPRESELEC_PROVIDER: {
        default: 'epreselec',
        priority: 'epreselecPriority',
    },
    COMPUTRABAJO_PROVIDER: {
        default: 'computrabajo',
        priority: 'computrabajoPriority',
    },
    PANDAPE_PROVIDER: {
        default: 'pandape',
        priority: 'pandapePriority',
    },
    TURIJOBS_PROVIDER: {
        default: 'turijobs',
        priority: 'turijobsPriority',
    },
    INDEED_PROVIDER: {
        default: 'indeed',
        priority: 'indeedPriority',
    },
    HELLOWORK_PROVIDER: {
        default: 'hellowork',
        priority: 'helloworkPriority',
    },
};

export const USER_AUTHORIZED_SECTION_KEYS = {
    INBOX: 'inbox',
    PUSH_OFFER: 'push_offer',
    PULL_CONFIG: 'pull_config',
    SAVED_SEARCHES: 'saved_searches',
    PLANNING: 'planning',
    FAVORITES: 'favorites',
    REPORTS: 'reports',
    EVALUATIVE_TESTS: 'evaluative_tests',
    STATISTICS_DASHBOARD: 'statistics_dashboard',
    RPA_CONFIG: 'rpa_config',
    TEMPLATE_REQUESTOR: 'template_requestor',
    TEMPLATE_VALIDATOR: 'template_validator',
    TEMPLATE_SUPERVISOR: 'template_supervisor',
}

export const DEFAULT_CHARTS_COLOR_PALETTE_CHATGPT = [
    'rgba(255, 211, 182, 0.9)', // Melocotón claro
    'rgba(255, 191, 174, 0.9)', // Rosa suave
    'rgba(255, 216, 194, 0.9)', // Rosa pastel
    'rgba(255, 209, 220, 0.9)', // Rosa chicle
    'rgba(181, 234, 215, 0.9)', // Verde menta claro
    'rgba(175, 225, 231, 0.9)', // Celeste pálido
    'rgba(162, 210, 255, 0.9)', // Azul cielo
    'rgba(226, 194, 255, 0.9)', // Lila suave
    'rgba(255, 223, 211, 0.9)', // Naranja pastel
    'rgba(255, 241, 181, 0.9)', // Amarillo pastel
];

export const DEFAULT_CHARTS_COLOR_PALETTE_PASTEL = [
    'rgba(135, 132, 216, 0.9)',
    'rgba(132, 166, 237, 0.9)',
    'rgba(142, 209, 225, 0.9)',
    'rgba(130, 218, 157, 0.9)',
    'rgba(162, 222, 108, 0.9)',
    'rgba(208, 221, 87, 0.9)',
    'rgba(255, 166, 88, 0.9)',
    'rgba(255, 94, 88, 0.9)',
];

export const DEFAULT_CHARTS_COLOR_PALETTE_PASTEL_2 = [
    '#FFCCCC', // Rosa claro
    '#FFD8B1', // Melocotón suave
    '#FFE6B3', // Amarillo pálido
    '#FFF3B0', // Amarillo claro
    '#CBE6FF', // Azul claro
    '#C7CEEA', // Lila claro
    '#C9F7C5', // Verde menta
    '#B3E6E6', // Celeste claro
    '#FAC8CD', // Rosa pastel
    '#FFDAC1', // Durazno suave
];

const DEFAULT_CHARTS_COLOR_PALETTE_HRBF = [
    '#4a85ff', // Color original
    '#3ee8b5', // Color original
    '#2959cc', // Variación más oscura
    '#28bfa3', // Variación más oscura
    '#1c31a0', // Variación aún más oscura
    '#1c998f', // Variación aún más oscura
    '#6388ff', // Variación más clara
    '#61edc4', // Variación más clara
    '#8aa3ff', // Variación aún más clara
    '#8cf1da', // Variación aún más clara
];

// Paleta para testear
export const DEFAULT_CHARTS_COLOR_PALETTE_RGB = [
  "red",
  "green",
  "blue",
]

export const PALETTES = {
    "1": DEFAULT_CHARTS_COLOR_PALETTE_CHATGPT,
    "2": DEFAULT_CHARTS_COLOR_PALETTE_PASTEL,
    "3": DEFAULT_CHARTS_COLOR_PALETTE_PASTEL_2,
    "hrbf": DEFAULT_CHARTS_COLOR_PALETTE_HRBF,
}

export const DEFAULT_WIDGET_DND_DATA_GRID = {
    "CircleStatistic": {"h": 4, "w": 4, "maxW": 12, "minW": 4, "minH": 4},
    "CircleMultipleStatistic": {"h": 6, "w": 6, "maxW": 12, "minW": 6},
    "TableStatistic": {"h": 6, "w": 6, "maxW": 12, "minW": 4},
    "BarChartStatistic": {"h": 6, "w": 6, "maxW": 12, "minW": 6},
    "FunnelStatistic": {"h": 6, "w": 6, "maxW": 12, "minW": 3, "minH": 6},
    "GaugeStatistic": {"h": 6, "w": 4, "maxW": 12, "minW": 4},
    "PieStatistic": {"h": 6, "w": 6, "maxW": 12, "minW": 4},
    "MapsStatistic": {"h": 6, "w": 6, "maxW": 12, "minW": 6},
    "HeatmapStatistic": {"h": 6, "w": 6, "maxW": 12, "minW": 6},
}

export const CUSTOM_USERS_DEFAULT_FILTERS = {
    [MODULES.ID_TALENT_ACQUISITION]: [
        'tag.name',
        'tags.name',
        'fase',
        'selection_status.phase',
        'selection_phase',
        'estado',
        'state.name',
        'selection_status',
        'evaluativeConfig.status',
        'cp',
        'direccion_obj.postcode',
        'direccion_obj.state',
        'direccion_obj.region',
        'direccion_obj.city',
        'zona_deseada',
        'centro_trabajo',
        'candidatura_seleccionada',
        'puesto',
        'otras_marcas',
    ],
    [MODULES.ID_ON_BOARDING]: [
        'dni',
        'tags.name',
        'fase',
        'responsable',
        'especialidad',
        'tecnico',
        'tienda_contrato',
        'tienda_formacion',
        'apellidos',
        'puesto_des',
        'centro_trabajo',
        'direccion_obj.city',
        'departamento',
        'email',
        'direccion_obj.region',
        'puesto',
        'direccion_obj.postcode',
        'responsable_rrhh',
        'lugar_de_trabajo',
    ],
    [MODULES.ID_WELCOME_PACK]: [],
    [MODULES.ID_EMPLOYEE_LINE]: [
        'dni',
        'state.name',
        'provincia',
        'zona_deseada',
        'employeeHasProcess.process.name',
        'departamento',
        'categoria',
        'centro_trabajo',
        'dni',
        'unidad',
        'dni',
        'dni',
        'dni',
    ],
    [MODULES.ID_FEEDBACK]: [
        'centro_trabajo',
        'departamento',
        'dni',
        'categoria',
        'region',
        'centro_tipo',
    ],
    [MODULES.ID_ABSENTEEISM]: [],
    [MODULES.ID_SURVEYS]: [],
    [MODULES.ID_VISUAL_TUTOR]: [],
    [MODULES.ID_EXIT_INTERVIEW]: [
        'colectivo',
        'dni',
        'especialidad',
        'subzona',
        'zona',
        'marca',
        'area',
        'departamento',
        'puesto',
        'responsable',
    ],
}

export const EVALUATION_CRITERIA = {
    RELEVANT: 'relevant',
    DESIRED: 'desired',
    MANDATORY: 'mandatory',
    IGNORE: 'ignore',
};

export const ALERT_LEVEL = {
    INFO: 'info',
    WARNING: 'warning',
    SUCCESS: 'success',
    ERROR: 'error',
};

export const STATES_TEMPLATE_REQUEST = {
    CANCELLED_STATE: 'cancelled',
    REJECTED_STATE: 'rejected',
    ACTIVE_STATE: 'active',
    CLOSED_STATE: 'closed',
    PENDING_STATE: 'pending',
    EMPTY_STATE: 'empty',
    INACTIVE_STATE: 'inactive',
}

export const REASONS_TEMPLATE_REQUEST = {
    GAP: 'gap',
    REPLACEMENT: 'replacement',
}

export const BACKEND_CLASSES = {
    PERSON: "App\\Entity\\Person",
}

export const ALLOWED_EMAILS = [
    "laura.rodriguez+dia@hrbotfactory.com",
    "laura.rodriguez+mcdonalds@hrbotfactory.com",
    "admin+dia@hrbotfactory.com",
    "admin+paraguas@hrbotfactory.com",
    "victor.wang+demota@hrbotfactory.com",
    "admin+citius@hrbotfactory.com"
];
