import React from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import {withI18n} from '../../hocs'
import Grid from '@material-ui/core/Grid'
import BoxVideoSentimentsStyled from './styled'
import {LinearPercentWithTitle} from "../../atoms";

const BoxVideoSentiments = ({videoAnalysis = {}, videoUrl, i18n}) => {
  const auth = localStorage.getItem("persist:auth");
  if (auth && !auth.includes("demo") && !auth.includes("hrbotfactory")) {
    return null
  }
  //const { radar } = videoAnalysis;
  //let videoAnalysisFormatted = videoAnalysis;

  /*
  < Grid item xs={4}>
          <img alt="radar" width={400} src={radar}/>
        </Grid>
        <Grid item xs={5}>
          <Grid container item className="sentiments">
            {videoAnalysisFormatted && Object.entries(videoAnalysisFormatted).map(([key, value]) => {

              if(key === 'created_at' || key === 'radar')
              {
                return null
              }
              return (<Grid className="sentimentsItem" item key={key}>
                <LinearPercentWithTitle key={key} title={i18n.videoSentiments[key]} value={Math.round(value * 100)}/>
              </Grid>)
            })}
          </Grid>
        </Grid>

*/

  return (
    <BoxVideoSentimentsStyled>
      <div className="header-table">
        <p>
          {i18n.videoSentiments.title}
        </p>
      </div>
      <Grid className='boxInfo' container rowSpacing={8} lg={12} justifyContent='space-between' direction='row'>
        <Grid item xs={6}>
          <video width="90%" controls>
            <source type="video/mp4" src={videoUrl}/>
          </video>
        </Grid>
        <Grid item xs={6}>
          <div class="summary">
            <p class="summary-title">{i18n.iaFake.title}</p>
            <p class="summary-text">{i18n.iaFake.description}
            </p>
          </div>
        </Grid>
      </Grid>
    </BoxVideoSentimentsStyled>
  )
}

BoxVideoSentiments.propTypes = {
  i18n: PropTypes.object.isRequired,
  variableFields: PropTypes.object
}

const enharce = compose(withI18n)

export default enharce(BoxVideoSentiments)
