import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { TemplateRequestManagerStyled } from './styled'
import { PageTitle } from '../../atoms'
import { Grid, Typography, Divider, TextField, Box } from '@material-ui/core'
import i18n from '../../../assets/i18n'
import moment from 'moment'
import routes from '../../../config/routes'
import BackButton from '../../atoms/back-button'
import SingleDate from '../../molecules/single-date-picker'
import Autocomplete from '@material-ui/lab/Autocomplete'
import RegularButton from '../../atoms/regular-button'
import { createTemplate } from '../../../api/index'
import { useMutation } from '@tanstack/react-query'
import InputField from '../../atoms/input-field'
import { useRequirements } from '../../../api/queries/useRequirements'
import { LIMITCANDIDATESNUMBER, ALERT_LEVEL } from '../../../config/constants'

import { useAppContext } from '../../../context/appContext'
import { useLocation } from 'react-router-dom'

let typeContractOptions = [
  { id: 1, name: i18n.templateRequestManager.typeContractIndefinite },
  { id: 2, name: i18n.templateRequestManager.typeContractTemporary },
  { id: 3, name: i18n.templateRequestManager.typeContractInterim }
]

let vacanciesOptions = new Array(10).fill(0).map((_, index) => ({ id: index + 1, name: `${index + 1}` }))

const ProcessCreateTemplateRequestManager = ({ isSupervisorView, user, client }) => {
  const { createAlert } = useAppContext()
  const { state } = useLocation()
  const history = useHistory()

  const VACANCY_STATES = {
    CANCELLED: 'cancelled',
    REJECTED: 'rejected',
    ACTIVE: 'active',
    CLOSED: 'closed',
    PENDING: 'pending',
    EMPTY: 'empty'
  }

  let statusOptions = [
    { id: VACANCY_STATES.CANCELLED, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.CANCELLED] },
    { id: VACANCY_STATES.REJECTED, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.REJECTED] },
    { id: VACANCY_STATES.ACTIVE, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.ACTIVE] },
    { id: VACANCY_STATES.CLOSED, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.CLOSED] },
    { id: VACANCY_STATES.PENDING, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.PENDING] },
    { id: VACANCY_STATES.EMPTY, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.EMPTY] }
  ]

  const VACANCY_REASONS = {
    GAP: 'gap',
    REPLACEMENT: 'replacement',
  }

  let reasonOptions = [
    { id: VACANCY_REASONS.GAP, value: i18n.templateRequestManager.vacancyReasons[VACANCY_REASONS.GAP] },
    { id: VACANCY_REASONS.REPLACEMENT, value: i18n.templateRequestManager.vacancyReasons[VACANCY_REASONS.REPLACEMENT] },
  ]

  const [focusedDateIncop, setFocusedDateIncop] = useState(null)
  const [positionOptions, setPositionOptions] = useState([])
  const [workplaceOptions, setWorkplaceOptions] = useState([])
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false)
  const [requirementsRequiredOptions, setRequirementsRequiredOptions] = useState([])
  const [requirementsDesirableOptions, setRequirementsDesirableOptions] = useState([])

  const initialState = {
    constraints: [],
    scoring_fields: [],
    dateIncorporation: state && state[0]?.start_date_at ? moment(state[0]?.start_date_at) : moment().add(7, 'days'),
    position:
      state && state[0]?.filters?.constraints?.candidature_id
        ? { id: state[0]?.filters?.constraints?.candidature_id, name: state[0]?.candidature_settings?.name }
        : '',
    statusProcess: state && state[0].state ? statusOptions.find(status => status.id === state[0].state).value : i18n.templateRequestManager.vacancyStates[VACANCY_STATES.PENDING],
    reason: state && state[0].reason ? reasonOptions.find(reason => reason.id === state[0].reason).value : null,
    typeContract:
      state && state[0]?.information?.contract_type ? { id: state[0]?.information?.contract_type, name: state[0]?.information?.temporary_contract } : '',
    workplace: state && state[0]?.client_place?.id ? { id: state[0]?.client_place?.id, name: state[0]?.client_place?.name } : '',
    filters: {
      constraints: {},
      scoring_fields: {}
    },
    information: {
      observations: state && state[0]?.information?.observations ? state[0]?.information?.observations : ''
    },
    limit_candidates: '',
    vacancies: state && state[0]?.vacancies ? { id: state[0]?.vacancies, name: String(state[0]?.vacancies) } : { id: 1, name: '1' }
  }

  const filtrarOptionsRequirements = obj => {
    let resultado = []
    for (let propiedad in obj) {
      if (typeof obj[propiedad] === 'string' && propiedad !== 'unidad' && propiedad !== 'candidatura_id') {
        resultado.push({ id: propiedad, name: obj[propiedad] })
      }
    }
    return resultado
  }

  const filterRequirements = newValues => {
    const updatedRequirementsRequiredOptions = requirementsRequiredOptions.filter(
      option => !newValues.some(newValue => newValue.id === option.id && newValue.name === option.name)
    )
    const updatedRequirementsRequiredOptionsOrder = sortArrayByField('name', updatedRequirementsRequiredOptions)
    setRequirementsRequiredOptions(updatedRequirementsRequiredOptionsOrder)

    const updatedRequirementsDesirableOptions = requirementsDesirableOptions.filter(
      option => !newValues.some(newValue => newValue.id === option.id && newValue.name === option.name)
    )
    const updatedRequirementsDesirableOptionsOrder = sortArrayByField('name', updatedRequirementsDesirableOptions)
    setRequirementsDesirableOptions(updatedRequirementsDesirableOptionsOrder)
  }

  const [formData, setFormData] = useState(initialState)

  const { data: requirementsData, refetch, isLoading } = useRequirements(formData.position.id, formData.position !== '')

  useEffect(() => {
    const candidatureConfigs = user.candidature_configs.length ? user.candidature_configs : client.candidature_configs
    const optionsPositions = candidatureConfigs.map(config => ({ id: config.id, name: config.name }))

    const staff = user.staffs

    let resultOptionsWorkplace = []
    staff.map(place =>
      place.client_places.map(clientPlace => {
        resultOptionsWorkplace.push({
          id: clientPlace.id,
          name: clientPlace.name
        })
      })
    )

    const positionOrder = sortArrayByField('name', optionsPositions)
    const clientPlaces = resultOptionsWorkplace.length ? resultOptionsWorkplace : client.client_places ?? []

    setPositionOptions(positionOrder)
    setWorkplaceOptions(clientPlaces)
  }, [])

  useEffect(() => {
    if (requirementsData) {
      const combinedOptions = requirementsData.map(item => ({
        id: item.question_remote_name,
        name: `${item.question_name} - ${item.answer_value}`
      }))

      const requirementsOrder = sortArrayByField('name', combinedOptions)

      setRequirementsRequiredOptions(requirementsOrder)
      setRequirementsDesirableOptions(requirementsOrder)
    }

    if (state && requirementsData) {
      // aca validar si el state.position es igual al seleccionado para mostrar sino no

      const dataRequirementsConstraints = filtrarOptionsRequirements(state[0]?.filters?.constraints)
      const filteredResultsConstraints = requirementsData
        ?.filter(item2 => dataRequirementsConstraints.some(item1 => item2.question_remote_name === item1.id && item2.answer_value === item1.name))
        .map(element => {
          return {
            id: element.question_remote_name,
            name: `${element.question_name} - ${element.answer_value}`
          }
        })

      const dataRequirementsScoringFields = filtrarOptionsRequirements(state[0]?.filters?.scoring_fields)
      const filteredResultsScoringFields = requirementsData
        ?.filter(item2 => dataRequirementsScoringFields.some(item1 => item2.question_remote_name === item1.id && item2.answer_value === item1.name))
        .map(element => {
          return {
            id: element.question_remote_name,
            name: `${element.question_name} - ${element.answer_value}`
          }
        })

      setFormData({ ...formData, constraints: filteredResultsConstraints, scoring_fields: filteredResultsScoringFields })
    }
  }, [formData.position, requirementsData])

  useEffect(() => {
    if (state) {
      setFormData(prevState => ({
        ...prevState,
        position: { id: state[0]?.filters?.constraints?.candidatura_id, name: state[0]?.candidature_settings?.name }
      }))
    }
  }, [state])

  const sortArrayByField = (field, array, ascending = true) => {
    return array.slice().sort((a, b) => {
      if (a[field] < b[field]) {
        return ascending ? -1 : 1
      }
      if (a[field] > b[field]) {
        return ascending ? 1 : -1
      }
      return 0
    })
  }

  const createProcess = async data => {
    try {
      await createTemplate(data)
      window.location.href = '/talent_acquisition/template-request-manager'
    } catch (error) {
      console.error('Error while creating process:', error)
      throw error
    }
  }

  const mutationCreateProcess = useMutation(createProcess)

  const handleChangePosition = (_, newValue) => {
    setFormData({ ...formData, scoring_fields: [], constraints: [], position: newValue })
    refetch()
  }

  const handleChangeStatus = (_, newValue) => {
    setFormData({ ...formData, statusProcess: newValue })
  }

  const handleChangeTypeContract = (_, newValue) => {
    setFormData({ ...formData, typeContract: newValue })
  }

  const handleChangeWorkplace = (_, newValue) => {
    setFormData({ ...formData, workplace: newValue })
  }

  const addRequirementScoringFields = newValues => {
    const updatedRequirementsRequiredOptions = [...requirementsRequiredOptions, newValues]
    const updatedRequirementsRequiredOptionsOrder = sortArrayByField('name', updatedRequirementsRequiredOptions)
    setRequirementsRequiredOptions(updatedRequirementsRequiredOptionsOrder)

    const updatedRequirementsDesirableOptions = [...requirementsDesirableOptions, newValues]
    const updatedRequirementsDesirableOptionsOrder = sortArrayByField('name', updatedRequirementsDesirableOptions)
    setRequirementsDesirableOptions(updatedRequirementsDesirableOptionsOrder)

    setFormData(prevState => {
      const newScoringFields = prevState.scoring_fields.filter(scoringField => scoringField.id !== newValues.id || scoringField.name !== newValues.name)
      return {
        ...prevState,
        scoring_fields: newScoringFields
      }
    })
  }

  const addRequirementConstraints = newValues => {
    const updatedRequirementsRequiredOptions = [...requirementsRequiredOptions, newValues]
    const updatedRequirementsRequiredOptionsOrder = sortArrayByField('name', updatedRequirementsRequiredOptions)
    setRequirementsRequiredOptions(updatedRequirementsRequiredOptionsOrder)

    const updatedRequirementsDesirableOptions = [...requirementsDesirableOptions, newValues]
    const updatedRequirementsDesirableOptionsOrder = sortArrayByField('name', updatedRequirementsDesirableOptions)
    setRequirementsDesirableOptions(updatedRequirementsDesirableOptionsOrder)

    setFormData(prevState => {
      const newConstraints = prevState.constraints.filter(constraint => constraint.id !== newValues.id || constraint.name !== newValues.name)
      return {
        ...prevState,
        constraints: newConstraints
      }
    })
  }

  const handleChangeRequirementsRequired = (_, newValues) => {
    const previousValue = formData.constraints

    if (previousValue.length > newValues.length) {
      const removedItem = previousValue.find(item => !newValues.includes(item))
      addRequirementConstraints(removedItem)
    } else {
      setFormData(prevState => {
        filterRequirements(newValues)

        const newConstraints = newValues.map(({ id, name }) => ({ id, name }))

        return {
          ...prevState,
          constraints: newConstraints
        }
      })
    }
  }

  const handleChangeRequirementsDesirable = (_, newValues) => {
    const previousValue = formData.scoring_fields

    if (previousValue.length > newValues.length) {
      const removedItem = previousValue.find(item => !newValues.includes(item))
      addRequirementScoringFields(removedItem)
    } else {
      setFormData(prevState => {
        filterRequirements(newValues)

        const newScoringFields = newValues.map(({ id, name }) => ({ id, name }))

        return {
          ...prevState,
          scoring_fields: newScoringFields
        }
      })
    }
  }

  const handleTextareaObservations = event => {
    const newText = event.target.value
    setFormData({
      ...formData,
      information: {
        observations: newText
      }
    })
  }

  const handleChangeVacancies = (_, newValue) => {
    setFormData({ ...formData, vacancies: newValue })
  }

  const handleChangeReason = (_, newValue) => {
    setFormData({ ...formData, reason: newValue })
  }

  const formatConstraints = constraints => {
    const formattedConstraints = {}
    constraints.forEach(constraint => {
      const nameFormatted = constraint.name.split(' - ')[1]
      formattedConstraints[constraint.id] = nameFormatted
    })
    return formattedConstraints
  }

  const handleCreateProcess = e => {
    e.preventDefault()

    if (
      !formData.dateIncorporation ||
      !formData.position ||
      !formData.statusProcess ||
      !formData.typeContract ||
      !formData.workplace ||
      !formData.reason
    ) {
      createAlert(i18n.templateRequestManager.emptyForm, ALERT_LEVEL.ERROR)
      return
    }

    const formattedConstraints = formatConstraints(formData.constraints)
    const formattedScoringFields = formatConstraints(formData.scoring_fields)

    const dataToSend = {
      contract_type: parseInt(formData.typeContract.id),
      start_date_at: formData.dateIncorporation.format('YYYY-MM-DD'),
      limit_candidates: formData.vacancies.id * LIMITCANDIDATESNUMBER,
      vacancies: formData.vacancies.id,
      information: {
        contract_type: String(formData.typeContract.id),
        temporary_contract: formData.typeContract.name,
        observations: formData.information.observations
      },
      filters: {
        constraints: {
          unidad: String(formData.workplace.id),
          candidatura_id: formData.position.id,
          ...formattedConstraints
        },
        scoring_fields: {
          ...formattedScoringFields
        }
      },
      state: formData.statusProcess.id,
      reason: formData.reason.id ?? null
    }

    mutationCreateProcess.mutate(dataToSend)
  }

  useEffect(() => {
    const areAllFieldsFilled = () => {
      return (
        formData.dateIncorporation &&
        formData.position &&
        formData.statusProcess &&
        formData.typeContract &&
        formData.workplace &&
        formData.filters.constraints.length > 0 &&
        formData.filters.scoring_fields.length > 0
      )
    }

    setIsAllFieldsFilled(areAllFieldsFilled())
  }, [
    formData.dateIncorporation,
    formData.position,
    formData.statusProcess,
    formData.typeContract,
    formData.workplace,
    formData.filters.constraints,
    formData.filters.scoring_fields
  ])

  return (
    <TemplateRequestManagerStyled>
      <Grid container className="title-container">
        <Grid item sm={12}>
          <BackButton
            title={i18n.templateRequestManager.backToTemplateRequestManager}
            onClick={() => {
              history.push(routes.templateRequestManager)
            }}
          />
        </Grid>
      </Grid>

      <Divider variant="inset" />

      <Grid container className="create-process-container">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box bgcolor="#ffffff" borderRadius={25} p={5}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="column" gridGap={20} mb={2}>
                    <PageTitle title={i18n.templateRequestManager.newProcess} />
                    <Typography variant="h5">{i18n.templateRequestManager.descriptionNewProcess}</Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" bgcolor="#fbfbfb" borderRadius={25} p={5} boxShadow={3}>
                    <Grid container spacing={3} className="group-input">
                      <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label className="required">{i18n.templateRequestManager.dateIncorporation} </label>
                          <SingleDate
                            date={formData.dateIncorporation}
                            onDateChange={date => setFormData({ ...formData, dateIncorporation: date })}
                            focused={focusedDateIncop}
                            onFocusChange={({ focused }) => setFocusedDateIncop(focused)}
                            isFetching={false}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label className="required">{i18n.templateRequestManager.reason}</label>
                          <Autocomplete
                            size="small"
                            className="autocomplete-custom"
                            value={formData.reason}
                            options={reasonOptions}
                            getOptionLabel={option => option.value || ''}
                            renderInput={params => <TextField {...params} variant="outlined" />}
                            disableClearable={true}
                            onChange={(event, newValue) => handleChangeReason(event, newValue)}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label className="required">{i18n.templateRequestManager.numberVacancies}</label>
                          <Autocomplete
                            size="small"
                            className="autocomplete-custom"
                            value={formData.vacancies}
                            options={vacanciesOptions}
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => <TextField {...params} variant="outlined" />}
                            disableClearable={true}
                            onChange={(event, newValue) => handleChangeVacancies(event, newValue)}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label className="required">{i18n.templateRequestManager.position}</label>
                          <Autocomplete
                            size="small"
                            className="autocomplete-custom"
                            value={formData.position}
                            options={positionOptions}
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => <TextField {...params} placeholder={i18n.templateRequestManager.placeholderPosition} variant="outlined" />}
                            disableClearable={true}
                            onChange={(event, newValue) => handleChangePosition(event, newValue)}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label className="required">{i18n.templateRequestManager.statusProcess} </label>
                          <InputField
                            placeholder={i18n.templateRequestManager.placeholderStatusProcess}
                            value={formData.statusProcess}
                            onChange={(event, newValue) => handleChangeStatus(event, newValue)}
                            disabled={!isSupervisorView}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" bgcolor="#fbfbfb" borderRadius={25} p={5} boxShadow={3}>
                    <Grid container spacing={3} className="group-input">
                      <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label className="required">{i18n.templateRequestManager.typeContract}</label>
                          <Autocomplete
                            size="small"
                            className="autocomplete-custom"
                            value={formData.typeContract}
                            options={typeContractOptions}
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => (
                              <TextField {...params} placeholder={i18n.templateRequestManager.placeholderTypeContract} variant="outlined" />
                            )}
                            disableClearable={true}
                            onChange={(event, newValue) => handleChangeTypeContract(event, newValue)}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label className="required">{i18n.templateRequestManager.workplace}</label>
                          <Autocomplete
                            size="small"
                            className="autocomplete-custom"
                            value={formData.workplace}
                            options={workplaceOptions}
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => <TextField {...params} placeholder={i18n.templateRequestManager.placeholderWorkplace} variant="outlined" />}
                            disableClearable={true}
                            onChange={(event, newValue) => handleChangeWorkplace(event, newValue)}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label>{i18n.templateRequestManager.mandatoryRequirements}</label>
                          <Autocomplete
                            multiple
                            size="small"
                            className="autocomplete-custom"
                            value={formData.constraints || []}
                            options={requirementsRequiredOptions}
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => (
                              <TextField {...params} placeholder={i18n.templateRequestManager.placeholderRequirementsRequired} variant="outlined" />
                            )}
                            limitTags={2}
                            onChange={(event, newValue) => handleChangeRequirementsRequired(event, newValue)}
                            disabled={formData.position === '' || isLoading}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label>{i18n.templateRequestManager.desirableRequirements}</label>
                          <Autocomplete
                            multiple
                            size="small"
                            className="autocomplete-custom"
                            value={formData.scoring_fields || []}
                            options={requirementsDesirableOptions}
                            getOptionLabel={option => option.name || ''}
                            renderInput={params => (
                              <TextField {...params} placeholder={i18n.templateRequestManager.placeholderRequirementsDesirable} variant="outlined" />
                            )}
                            limitTags={2}
                            onChange={(event, newValue) => handleChangeRequirementsDesirable(event, newValue)}
                            disabled={formData.position === '' || isLoading}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center" bgcolor="#fbfbfb" borderRadius={25} p={5} boxShadow={3}>
                    <Grid container spacing={3} className="group-input">
                      <Grid item xs={12}>
                        <Box display="flex" flexDirection="column" gridGap={5}>
                          <label>{i18n.templateRequestManager.observations}</label>
                          <textarea
                            className="textarea-question"
                            rows={2}
                            name="observations"
                            onChange={(event, newText) => handleTextareaObservations(event, newText)}
                            placeholder={i18n.templateRequestManager.placeholderObservations}
                            value={formData.information.observations}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box
                    item
                    display="flex"
                    justifyContent="flex-end"
                    className="container-item-vacancies"
                    style={{ display: !isAllFieldsFilled ? 'flex' : 'none' }}
                    mt={2}
                  >
                    <RegularButton
                      className="button-custom"
                      title={i18n.templateRequestManager.saveProcess}
                      onClick={handleCreateProcess}
                      disabled={isAllFieldsFilled}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </TemplateRequestManagerStyled>
  )
}

export default ProcessCreateTemplateRequestManager
