import React, {Component} from 'react'
import compose from 'recompose/compose'
import {withI18n} from '../../hocs'
import Grid from '@material-ui/core/Grid'
import {MailSvgStyled, ObservationsTableStyled} from './styled'
import {Button, DialogContent} from '@material-ui/core'
import {AvatarName, InputField, ModalHeader, ProfileAvatar} from '../../atoms'
import PropTypes from 'prop-types'
import i18n from '../../../assets/i18n'
import _ from 'lodash'
import moment from 'moment'
import Tooltip from '@material-ui/core/Tooltip';
import {ReactComponent as MailIcon} from '../../../assets/images/mail.svg'
import {ModalStyled} from "../modal-announcement/styled";
import Modal from "@material-ui/core/Modal";


class ObservationsTable extends Component {

  state = {
    observationInput: '',
    isOpenMailCustomModalId: null
  }

  handleOnChange = value => {
    this.setState({ observationInput: value })
  }

  handleOnSubmit = () => {
    const { observationInput } = this.state
    this.props.handleAddObservation(observationInput)
    this.setState({observationInput: ''})
  }

  render() {
    const observations = _.orderBy(this.props.observations, 'created_at', 'desc')
    let obs = ''

    if (observations) {
      obs = observations.map((observation, i) => {
        const date = _.get(observation, 'created_at')
        const formatDate = date ? moment(date).format('D/M/YYYY') : ''
        const time = date ? moment(date).format('HH:mm') : ''
        const message = _.get(observation, 'change_msg')
        const userName = _.get(observation, 'user.name', i18n.observationsTable.deleted_user_name)
        const userSurname = _.get(observation, 'user.surname', i18n.observationsTable.deleted_user_surname)
        const userImage = _.get(observation, 'user.avatar', '')
        const noPrint = message.startsWith("Cambio de estado ") || message.startsWith("Cambio de etiquetas ")

        let mails = observation.mails.map((mail) => (
          <>
            <Tooltip title={
              <React.Fragment>
                {mail.body.includes(mail.subject) ? <span dangerouslySetInnerHTML={{__html: mail.body}}/> : mail.subject }
              </React.Fragment>
            }>
              <MailSvgStyled>
                <MailIcon onClick={()=>{this.setState({isOpenMailCustomModalId: observation.id})}}/>
              </MailSvgStyled>
            </Tooltip>

            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.isOpenMailCustomModalId === observation.id}
              onClose={()=>{this.setState({isOpenMailCustomModalId: null})}}
            >
              <ModalStyled>
                <ModalHeader Icon={<MailIcon />} title={mail.body.includes(mail.subject) ? i18n.requireDoc.docRequest : mail.subject } closeClick={()=>{this.setState({isOpenMailCustomModalId: null})}}/>
                <div className="modal-body">
                  <DialogContent dangerouslySetInnerHTML={{__html: mail.body}}>
                  </DialogContent>
                </div>
              </ModalStyled>
            </Modal>
          </>
        ))

        return (
          <Grid key={i} container alignItems={'center'} className={'row-table pagebreakavoid'+(noPrint ? ' no-print' : '')}>
            <Grid item xs={2} lg={1}>
              <p>{formatDate}</p>
            </Grid>
            <Grid item xs={2} lg={1}>
              <p>{time}</p>
            </Grid>
            <Grid item xs={5} lg={8}>
              <p>{message}{mails}</p>
            </Grid>
            <Grid item xs={3} lg={2}>
              <AvatarName imageUrl={userImage} name={`${userName} ${userSurname}`} />
            </Grid>
          </Grid>
        )
      })
    }

    const isDisabled = this.props.isFetching || !this.state.observationInput

    return (
      <ObservationsTableStyled>
        {/*<p className="title-table">{i18n.observationsTable.title}</p>*/}

        <Grid container className="header-table">
          <Grid item xs={2} lg={1}>
            <p>{i18n.observationsTable.date}</p>
          </Grid>
          <Grid item xs={2} lg={1}>
            <p>{i18n.observationsTable.time}</p>
          </Grid>
          <Grid item xs={5} lg={8}>
            <p>{i18n.observationsTable.interviewDate}</p>
          </Grid>
          <Grid item xs={3} lg={2}>
            <p>{i18n.observationsTable.author}</p>
          </Grid>
        </Grid>
        <div className="wrap-table">
          {this.props.showObsInput ? (
            <Grid container alignItems={'center'} className={'row-table row-add'}>
              <Grid item xs={2} lg={1}>
                <p>{i18n.observationsTable.today}</p>
              </Grid>
              <Grid item xs={2} lg={1}>
                <p>{i18n.observationsTable.now}</p>
              </Grid>
              <Grid item xs={5} lg={8}>
                <Grid container>
                  <Grid item xs={8} lg={10}>
                    <InputField
                      value={this.state.observationInput}
                      handleOnChange={this.handleOnChange}
                      placeholder={i18n.observationsTable.addObservation}
                    />
                  </Grid>
                  <Grid item xs={4} lg={2}>
                    <Button color="primary" onClick={this.handleOnSubmit} variant="contained" className={'add-button'} disabled={isDisabled}>
                      {i18n.observationsTable.add}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3} lg={2}>
                <ProfileAvatar size='small' styledType={'observation-table'} profileRedirect={false} />
              </Grid>
            </Grid>
          ) : (
            ''
          )}

          {obs}
        </div>
      </ObservationsTableStyled>
    )
  }
}

ObservationsTable.propTypes = {
  handleAddObservation: PropTypes.func,
  observationInput: PropTypes.string,
  handleOnChange: PropTypes.func,
  observations: PropTypes.array,
  showObsInput: PropTypes.bool,
  isFetching: PropTypes.bool,
}

const enharce = compose(withI18n)

export default enharce(ObservationsTable)
