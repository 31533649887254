import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Box, Divider, Chip, TextField, Typography, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import { alpha } from '@material-ui/core/styles'

import { DateInput, RegularButton } from '../../atoms'
import { ModalSearch, RegularButtonOutline } from '../../molecules'

import moment from 'moment'

import { useAppContext } from '../../../context/appContext'
import { ALERT_LEVEL, SECTION_KEYS, STATES_TEMPLATE_REQUEST } from '../../../config/constants'

import i18n from '../../../assets/i18n'
import routes from '../../../config/routes'

import Open from './components/Open'
import Close from './components/Close'

import { TemplateRequestManagerStyled } from './styled'
import { FeatureGuard } from '../../../utils/guard'
import { Autocomplete } from '@material-ui/lab'
import { useUsersForFilters } from '../../../api/queries/useUsers'

const useStyles = makeStyles({
  switchBase: {
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.4)'
    }
  }
})

const View = ({ user, client }) => {
  const theme = useTheme()
  const classes = useStyles()
  const { createAlert, filters: contextFilters, setFilters: setContextFilters } = useAppContext()
  const { data: dataUsers } = useUsersForFilters({
    select: function (data) {
      const regex = new RegExp('api');
      return data.filter(function (user) {
        return (regex.test(user.name) || regex.test(user.email)) === false;
      });
    }
  });

  const getContextFilters = () => {
    return contextFilters[SECTION_KEYS.VACANCY_REQUESTS]?.filters ?? {}
  }

  const setContextModuleFilters = (formFilters) => {
    let tempFilters = contextFilters;
    tempFilters[SECTION_KEYS.VACANCY_REQUESTS].filters = formFilters;

    setContextFilters(tempFilters);
  }

  const getContextShowOpenProcesses = () => {
    return contextFilters[SECTION_KEYS.VACANCY_REQUESTS]?.showOpenProcesses ?? true
  }

  const setContextShowOpenProcesses = (showOpenProcesses) => {
    let tempFilters = contextFilters;
    tempFilters[SECTION_KEYS.VACANCY_REQUESTS].showOpenProcesses = showOpenProcesses;

    setContextFilters(tempFilters);
  }

  const [rowSelected, setRowSelected] = React.useState([])

  const [positionOptions, setPositionOptions] = React.useState([])
  const [workplaceOptions, setWorkplaceOptions] = React.useState([])
  const [isOpen, setIsOpen] = React.useState(false)

  const initialFormData = {
    clientPlace: getContextFilters()?.clientPlace ?? [],
    state: getContextFilters()?.state ?? [],
    user: getContextFilters()?.user ?? [],
    validatorUser: getContextFilters()?.validatorUser ?? [],
    createdAt: getContextFilters()?.createdAt ?? '',
    endAt: getContextFilters()?.endAt ?? '',
    startDateAt: getContextFilters()?.startDateAt ?? '',
    endDateAt: getContextFilters()?.endDateAt ?? '',
    candidatureSettings: getContextFilters()?.candidatureSettings ?? [],
    contractType: getContextFilters()?.contractType ?? []
  }

  const [formData, setFormData] = React.useState(initialFormData)

  const clearFormData = () => {
    let emptyForm = {};
    for (var field in formData) {
      let valueField = formData[field];
      if (Array.isArray(valueField)) {
        emptyForm[field] = [];
      } else {
        emptyForm[field] = '';
      }
    }
    setFormData(emptyForm);
  }

  React.useEffect(() => {
    const candidatureConfigs = client?.candidature_configs
    const optionsPositions = candidatureConfigs.map(config => ({ id: config?.id, name: config?.remote_name }))

    const staff = user?.staffs

    let resultOptionsWorkplace = []

    staff.map(place =>
      place.client_places.map(clientPlace => {
        resultOptionsWorkplace.push({
          id: clientPlace?.id,
          name: clientPlace?.name
        })
      })
    )

    const clientPlaces = resultOptionsWorkplace.length ? resultOptionsWorkplace : client.client_places ?? []

    setPositionOptions(optionsPositions)
    setWorkplaceOptions(clientPlaces)
  }, [])

  const isValidator = true

  const statusActive = [
    { id: STATES_TEMPLATE_REQUEST.ACTIVE_STATE, name: i18n.templateRequestManager.vacancyStates.active },
    { id: STATES_TEMPLATE_REQUEST.PENDING_STATE, name: i18n.templateRequestManager.vacancyStates.pending },
    { id: STATES_TEMPLATE_REQUEST.EMPTY_STATE, name: i18n.templateRequestManager.vacancyStates.empty }
  ]

  const statusClosed = [
    { id: STATES_TEMPLATE_REQUEST.CANCELLED_STATE, name: i18n.templateRequestManager.vacancyStates.cancelled },
    { id: STATES_TEMPLATE_REQUEST.CLOSED_STATE, name: i18n.templateRequestManager.vacancyStates.closed },
    { id: STATES_TEMPLATE_REQUEST.INACTIVE_STATE, name: i18n.templateRequestManager.vacancyStates.inactive }
  ]

  let typeContractOptions = [
    { id: 1, name: i18n.templateRequestManager.typeContractIndefinite },
    { id: 2, name: i18n.templateRequestManager.typeContractTemporary },
    { id: 3, name: i18n.templateRequestManager.typeContractInterim }
  ]

  const handleButtonClick = () => {
    setContextModuleFilters({})
    clearFormData()
    setContextShowOpenProcesses(!getContextShowOpenProcesses())
  }

  const handleSubmit = () => {
    if (isEmpty(formData)) {
      createAlert(i18n.templateRequestManager.emptyForm, ALERT_LEVEL.ERROR)
      return
    }

    setIsOpen(false)
    setContextModuleFilters(formData)
  }

  const isEmpty = obj => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (!isEmpty(obj[key])) {
            return false
          }
        } else if (obj[key] !== '') {
          return false
        }
      }
    }
    return true
  }

  const users = dataUsers?.map(user => ({ id: user.id, name: user.name + ' ' + user.surname })) || []

  const optionsFilter = [
    { id: 'clientPlace', value: formData.clientPlace, label: i18n.modalSearch.workCenter },
    { id: 'state', value: formData.state, label: i18n.modalSearch.processStatus },
    { id: 'user', value: formData.user, label: i18n.modalSearch.applicant },
    { id: 'validatorUser', value: formData.validatorUser, label: i18n.modalSearch.validator },
    { id: 'createdAt', value: formData.createdAt, label: i18n.modalSearch.creationDate },
    { id: 'endAt', value: formData.endAt, label: i18n.modalSearch.creationDate },
    { id: 'startDateAt', value: formData.startDateAt, label: i18n.modalSearch.expectedDateOfIncorporation },
    { id: 'endDateAt', value: formData.endDateAt, label: i18n.modalSearch.expectedDateOfIncorporation },
    { id: 'candidatureSettings', value: formData.candidatureSettings, label: i18n.modalSearch.position },
    { id: 'contractType', value: formData.contractType, label: i18n.modalSearch.typeOfContract }
  ]

  const formatDate = date => {
    date = date && moment(date).isValid() ? moment(date) : null
    return date
  }

  const filterOptions = options => {
    return options.filter(option => {
      const value = formData[option.id]
      if (Array.isArray(value)) {
        return value.length > 0
      } else {
        return value !== undefined && value !== null && value !== ''
      }
    })
  }

  return (
    <TemplateRequestManagerStyled>
      <Grid container className="title-container">
        <Grid item className="sectionSecond" xs={12} sm={12}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box mb={2}>
              <Typography
                variant="h1"
                style={{ color: '#000000', display: 'inline', fontSize: '28px', fontFamily: 'Inter', fontWeight: '600', lineHeight: '1.167' }}
              >
                {i18n.templateRequestManager.titleSection}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', placeSelf: 'flex-end', gridGap: '15px' }}>
              {FeatureGuard.canAccessToVacancyRequest() && (
                <ModalSearch isOpen={isOpen} setIsOpen={setIsOpen}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        bgcolor="#ffffff"
                        borderRadius={25}
                        p={4}
                        mb={2}
                        gridGap={20}
                        style={{
                          boxShadow: '0px 0px 1px 0px rgba(227,228,229,1),0px 3px 12px 0px rgba(9,30,66,0.14)'
                        }}
                      >
                        <Grid container spacing={2}>
                          <Chip
                            label={i18n.modalSearch.chipGroupLocation}
                            color="primary"
                            style={{
                              boxShadow: '0px 0px 1px 0px rgba(227,228,229,1),0px 3px 12px 0px rgba(9,30,66,0.14)',
                              fontSize: '14px',
                              fontWeight: 600,
                              backgroundColor: alpha(theme.palette.primary.main, 0.8)
                            }}
                          />
                        </Grid>
                        <Grid container spacing={2} className="group-input">
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="space-between" alignItems="center" sx={{ gap: 10 }}>
                              <Box
                                component="label"
                                className="required"
                                width={180}
                                style={{
                                  fontWeight: 400,
                                  fontSize: '14px',
                                  lineHeight: '21px'
                                }}
                              >
                                {i18n.modalSearch.workCenter}
                              </Box>
                              <Autocomplete
                                size="small"
                                multiple={true}
                                className="autocomplete-custom"
                                value={formData.clientPlace}
                                options={workplaceOptions}
                                getOptionLabel={option => option.name || ''}
                                renderInput={params => (
                                  <TextField {...params} placeholder={i18n.templateRequestManager.placeholderWorkplace} variant="outlined" />
                                )}
                                disableClearable={true}
                                onChange={(_, newValue) => {
                                  const selectedIds = newValue.map(option => option.id)
                                  const dataUpdate = workplaceOptions.filter(option => selectedIds.includes(option.id))

                                  setFormData(prevState => ({
                                    ...prevState,
                                    clientPlace: dataUpdate
                                  }))
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        bgcolor="#ffffff"
                        borderRadius={25}
                        p={4}
                        mb={2}
                        gridGap={20}
                        style={{
                          boxShadow: '0px 0px 1px 0px rgba(227,228,229,1),0px 3px 12px 0px rgba(9,30,66,0.14)'
                        }}
                      >
                        <Grid container spacing={2}>
                          <Chip
                            label={i18n.modalSearch.chipGroupProcess}
                            color="primary"
                            style={{
                              boxShadow: '0px 0px 1px 0px rgba(227,228,229,1),0px 3px 12px 0px rgba(9,30,66,0.14)',
                              fontSize: '14px',
                              fontWeight: 600,
                              backgroundColor: alpha(theme.palette.primary.main, 0.8)
                            }}
                          />
                        </Grid>
                        <Grid container spacing={2} className="group-input">
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="column" gridGap={10}>
                              <Box display="flex" flexDirection="space-between" alignItems="center" sx={{ gap: 10 }}>
                                <Box
                                  component="label"
                                  className="required"
                                  width={180}
                                  style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '21px'
                                  }}
                                >
                                  {i18n.modalSearch.processStatus}
                                </Box>
                                <Autocomplete
                                  size="small"
                                  multiple={true}
                                  className="autocomplete-custom"
                                  value={formData.state}
                                  options={getContextShowOpenProcesses() ? statusActive : statusClosed}
                                  getOptionLabel={option => option.name || ''}
                                  renderInput={params => <TextField {...params} placeholder={i18n.modalSearch.placeholderSelect} variant="outlined" />}
                                  disableClearable={true}
                                  onChange={(_, newValue) => {
                                    const selectedIds = newValue.map(option => option.id)
                                    let dataUpdate = []
                                    if (getContextShowOpenProcesses()) {
                                      dataUpdate = statusActive.filter(option => selectedIds.includes(option.id))
                                    } else {
                                      dataUpdate = statusClosed.filter(option => selectedIds.includes(option.id))
                                    }
                                    setFormData(prevState => ({
                                      ...prevState,
                                      state: dataUpdate
                                    }))
                                  }}
                                />
                              </Box>

                              <Box display="flex" flexDirection="space-between" alignItems="center" sx={{ gap: 10 }}>
                                <Box
                                  component="label"
                                  className="required"
                                  width={180}
                                  style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '21px'
                                  }}
                                >
                                  {i18n.modalSearch.applicant}
                                </Box>
                                <Autocomplete
                                  size="small"
                                  multiple={true}
                                  className="autocomplete-custom"
                                  value={formData.user}
                                  options={users}
                                  getOptionLabel={option => option.name || ''}
                                  renderInput={params => <TextField {...params} placeholder={i18n.modalSearch.placeholderSelect} variant="outlined" />}
                                  disableClearable={true}
                                  onChange={(_, newValue) => {
                                    const selectedIds = newValue.map(option => option.id)
                                    const dataUpdate = users.filter(option => selectedIds.includes(option.id))

                                    setFormData(prevState => ({
                                      ...prevState,
                                      user: dataUpdate
                                    }))
                                  }}
                                />
                              </Box>

                              <Box display="flex" flexDirection="space-between" alignItems="center" sx={{ gap: 10 }}>
                                <Box
                                  component="label"
                                  className="required"
                                  width={180}
                                  style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '21px'
                                  }}
                                >
                                  {i18n.modalSearch.validator}
                                </Box>
                                <Autocomplete
                                  size="small"
                                  multiple={true}
                                  className="autocomplete-custom"
                                  value={formData.validatorUser}
                                  options={users}
                                  getOptionLabel={option => option.name || ''}
                                  renderInput={params => <TextField {...params} placeholder={i18n.modalSearch.placeholderSelect} variant="outlined" />}
                                  disableClearable={true}
                                  onChange={(_, newValue) => {
                                    const selectedIds = newValue.map(option => option.id)
                                    const dataUpdate = users.filter(option => selectedIds.includes(option.id))

                                    setFormData(prevState => ({
                                      ...prevState,
                                      validatorUser: dataUpdate
                                    }))
                                  }}
                                />
                              </Box>

                              <Box display="flex" flexDirection="space-between" alignItems="center">
                                <Box
                                  component="label"
                                  className="required"
                                  width={145}
                                  minWidth={130}
                                  style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '21px'
                                  }}
                                  pr={2}
                                >
                                  {i18n.modalSearch.creationDate}
                                </Box>
                                <Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: 10 }}>
                                  <DateInput
                                    value={formatDate(formData.createdAt)}
                                    format="DD/MM/YYYY"
                                    onChange={date => setFormData({ ...formData, createdAt: date })}
                                    placeholder={i18n.modalFilter.fromDate}
                                    name="created_date"
                                  />

                                  <DateInput
                                    value={formatDate(formData.endAt)}
                                    format="DD/MM/YYYY"
                                    onChange={date => setFormData({ ...formData, endAt: date })}
                                    placeholder={i18n.modalFilter.toDate}
                                    name="end_date"
                                  />
                                </Box>
                              </Box>

                              <Box display="flex" flexDirection="space-between" alignItems="center">
                                <Box
                                  component="label"
                                  className="required"
                                  width={145}
                                  minWidth={130}
                                  style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '21px'
                                  }}
                                  pr={2}
                                >
                                  {i18n.modalSearch.expectedDateOfIncorporation}
                                </Box>

                                <Box display="flex" flexDirection="row" alignItems="center" sx={{ gap: 10 }}>
                                  <DateInput
                                    value={formatDate(formData.startDateAt)}
                                    format="DD/MM/YYYY"
                                    onChange={date => setFormData({ ...formData, startDateAt: date })}
                                    placeholder={i18n.modalFilter.fromDate}
                                    name="start_date_at"
                                  />

                                  <DateInput
                                    value={formatDate(formData.endDateAt)}
                                    format="DD/MM/YYYY"
                                    onChange={date => setFormData({ ...formData, endDateAt: date })}
                                    placeholder={i18n.modalFilter.toDate}
                                    name="end_date_at"
                                  />
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        bgcolor="#ffffff"
                        borderRadius={25}
                        p={4}
                        mb={4}
                        gridGap={20}
                        style={{
                          boxShadow: '0px 0px 1px 0px rgba(227,228,229,1),0px 3px 12px 0px rgba(9,30,66,0.14)'
                        }}
                      >
                        <Grid container spacing={2}>
                          <Chip
                            label={i18n.modalSearch.chipGroupPreferences}
                            color="primary"
                            style={{
                              boxShadow: '0px 0px 1px 0px rgba(227,228,229,1),0px 3px 12px 0px rgba(9,30,66,0.14)',
                              fontSize: '14px',
                              fontWeight: 600,
                              backgroundColor: alpha(theme.palette.primary.main, 0.8)
                            }}
                          />
                        </Grid>
                        <Grid container spacing={2} className="group-input">
                          <Grid item xs={12}>
                            <Box display="flex" flexDirection="column" gridGap={10}>
                              <Box display="flex" flexDirection="space-between" alignItems="center" sx={{ gap: 10 }}>
                                <Box
                                  component="label"
                                  className="required"
                                  width={180}
                                  style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '21px'
                                  }}
                                >
                                  {i18n.modalSearch.position}
                                </Box>
                                <Autocomplete
                                  size="small"
                                  multiple={true}
                                  className="autocomplete-custom"
                                  value={formData.candidatureSettings}
                                  options={positionOptions}
                                  getOptionLabel={option => option.name || ''}
                                  renderInput={params => <TextField {...params} placeholder={i18n.modalSearch.placeholderSelect} variant="outlined" />}
                                  disableClearable={true}
                                  onChange={(_, newValue) => {
                                    const selectedIds = newValue.map(option => option.id)
                                    const dataUpdate = positionOptions.filter(option => selectedIds.includes(option.id))

                                    setFormData(prevState => ({
                                      ...prevState,
                                      candidatureSettings: dataUpdate
                                    }))
                                  }}
                                />
                              </Box>

                              <Box display="flex" flexDirection="space-between" alignItems="center" sx={{ gap: 10 }}>
                                <Box
                                  component="label"
                                  className="required"
                                  width={180}
                                  style={{
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    lineHeight: '21px'
                                  }}
                                >
                                  {i18n.modalSearch.typeOfContract}
                                </Box>
                                <Autocomplete
                                  size="small"
                                  multiple={true}
                                  className="autocomplete-custom"
                                  value={formData.contractType}
                                  options={typeContractOptions}
                                  getOptionLabel={option => option.name || ''}
                                  renderInput={params => <TextField {...params} placeholder={i18n.modalSearch.placeholderSelect} variant="outlined" />}
                                  disableClearable={true}
                                  onChange={(_, newValue) => {
                                    const selectedIds = newValue.map(option => option.id)
                                    const dataUpdate = typeContractOptions.filter(option => selectedIds.includes(option.id))

                                    setFormData(prevState => ({
                                      ...prevState,
                                      contractType: dataUpdate
                                    }))
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box display="flex" flexDirection="row" justifyContent="flex-end" gridGap={20}>
                            <RegularButtonOutline
                              title={i18n.modalSearch.clearFilter}
                              onClick={() => {
                                setContextModuleFilters({})
                                clearFormData()
                              }}
                            />
                            <RegularButton title={i18n.modalSearch.find} onClick={handleSubmit} />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </ModalSearch>
              )}

              {isValidator && (
                <>
                  <Link
                    to={{
                      pathname: routes.createProcess,
                      state: rowSelected
                    }}
                    query={{ clone: rowSelected }}
                  >
                    <RegularButtonOutline
                      className="button-custom"
                      title={i18n.templateRequestManager.cloneProcess}
                      disabled={rowSelected.length === 0}
                    ></RegularButtonOutline>
                  </Link>

                  <Link to={{ pathname: routes.createProcess }} query={{ clone: rowSelected }}>
                    <RegularButton className="button-custom" title={i18n.templateRequestManager.createProcess} />
                  </Link>
                </>
              )}
            </Box>
          </Box>
          <Divider variant="fullWidth" />
          <Box mb={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <h5>{i18n.templateRequestManager.descriptionSection}</h5>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid item className="headerTable" display="flex" style={{ justifyContent: 'space-between', alignItems: 'flex-end', gap: 20 }}>
            <Box component="div">
              {filterOptions(optionsFilter)?.length > 0 && Object.keys(getContextFilters()).length > 0 && (
                <Box display="flex" flexDirection="column" gridColumnGap={2}>
                  <Box component="label" mb={1}>
                    {i18n.modalSearch.filters}
                  </Box>

                  <Box display="flex" flexWrap="wrap" gridGap={4}>
                    {filterOptions(optionsFilter).map(option =>
                      Array.isArray(formData[option.id]) ? (
                        formData[option.id].map((value, index) => (
                          <Chip
                            key={`${option.id}-${index}`}
                            variant="outlined"
                            label={`${option.label}: ${value.name || value}`}
                            onDelete={() => {
                              const updatedFormData = { ...formData }
                              updatedFormData[option.id] = updatedFormData[option.id].filter(item => item !== value)
                              setFormData(updatedFormData)
                              setContextModuleFilters(updatedFormData)
                            }}
                          />
                        ))
                      ) : (
                        <Chip
                          key={option.id}
                          variant="outlined"
                          label={`${option.label}: ${formData[option.id]}`}
                          onDelete={() => {
                            const updatedFormData = { ...formData }
                            updatedFormData[option.id] = ''
                            setFormData(updatedFormData)
                            setContextModuleFilters(updatedFormData)
                          }}
                        />
                      )
                    )}
                  </Box>
                </Box>
              )}
            </Box>
            <Box component="div" display="flex" style={{ gap: 20 }}>
              {isValidator && (
                <Typography component="div">
                  <Box component="label" display="flex" alignItems="center" spacing={1}>
                    <Box>{i18n.templateRequestManager.buttonClosedProcess}</Box>
                    <Box>
                      <Switch checked={getContextShowOpenProcesses()} onChange={handleButtonClick} classes={{ switchBase: classes.switchBase }} />
                    </Box>
                    <Box>{i18n.templateRequestManager.buttonOpenedProcess}</Box>
                  </Box>
                </Typography>
              )}
            </Box>
          </Grid>

          {getContextShowOpenProcesses() ? (
            <Open rowSelected={rowSelected} setRowSelected={setRowSelected} showOpenProcesses={getContextShowOpenProcesses()} search={getContextFilters()} />
          ) : (
            <Close rowSelected={rowSelected} setRowSelected={setRowSelected} showOpenProcesses={getContextShowOpenProcesses()} search={getContextFilters()} />
          )}
        </Grid>
      </Grid>
    </TemplateRequestManagerStyled>
  )
}

export default View
