import React from 'react'
import PropTypes from 'prop-types'
import { ButtonStyled } from './style'

const ButtonWithIcon = ( { title, Icon, onClick,remove, disabled = false, isDeleteOfferBtn= false, svgFill = false, tooltip = false } ) => {
    return (
        <ButtonStyled disabled={ disabled } className="no-print" remove={remove} onClick={onClick} svgFill={svgFill} tooltip={tooltip}
                        style={isDeleteOfferBtn ? {position: 'absolute', minWidth: 36, maxHeight: 32, padding: '0 !important'} : {}} isDeleteOfferBtn={isDeleteOfferBtn}>
            {Icon && Icon}
            {title}
        </ButtonStyled>
    )
}

ButtonWithIcon.propType = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    Icon: PropTypes.func
}

export default ButtonWithIcon;