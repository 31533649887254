import React from 'react'
import { TemplateRequestTable } from '../../../organism'

import i18n from '../../../../assets/i18n'
import { useVacancyRequestManagerClose } from '../../../../api/queries/useVacancyRequestManager'
import { useAppContext } from '../../../../context/appContext'
import { SECTION_KEYS } from '../../../../config/constants'

const Close = ({ rowSelected, setRowSelected, showOpenProcesses, search }) => {
  const { sort: sortContext, setSort: setSortContext, range: rangeContext, setRange: setRangeContext } = useAppContext()

  const getSortFromContext = () => {
    return sortContext[SECTION_KEYS.VACANCY_REQUESTS]
  }

  const setSortFromContext = (sortLocalState) => {
    let temp = sortContext;
    temp[SECTION_KEYS.VACANCY_REQUESTS] = sortLocalState;

    setSortContext(temp);
  }

  const getRangeFromContext = () => {
    return rangeContext[SECTION_KEYS.VACANCY_REQUESTS]
  }

  const setRangeFromContext = (rangeLocalState) => {
    let temp = rangeContext;
    temp[SECTION_KEYS.VACANCY_REQUESTS] = rangeLocalState;

    setRangeContext(temp);
  }

  const getPerPageFromContextRange = () => {
    let [start, end] = range
    return (end - start) + 1
  }

  const getPageFromContextRange = () => {
    let [start, end] = range
    return Math.floor(start / getPerPageFromContextRange());
  }

  const [range, setRange] = React.useState(getRangeFromContext())
  const [sort, setSort] = React.useState(getSortFromContext())

  const [page, setPage] = React.useState(getPageFromContextRange())
  const [perPage, setPerPage] = React.useState(getPerPageFromContextRange())

  const { data, refetch, isLoading } = useVacancyRequestManagerClose(range, sort, search)

  const getRange = (pageVal, perPageVal) => {
    return [pageVal * perPageVal, perPageVal * (pageVal + 1) - 1]
  }

  React.useEffect(() => {
    refetch()
  }, [showOpenProcesses, search])

  React.useEffect(() => {
    setRange(getRange(page, perPage))
  }, [page, perPage])

  React.useEffect(() => {
    setSortFromContext(sort)
  }, [sort])

  React.useEffect(() => {
    setRangeFromContext(range)
  }, [range])

  return (
    <>
      <TemplateRequestTable
        data={data?.data}
        loading={isLoading}
        total={data?.total}
        i18n={i18n}
        rowSelected={rowSelected}
        setRowSelected={setRowSelected}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        range={range}
        setRange={setRange}
        sort={sort}
        setSort={setSort}
        showOpenProcesses={showOpenProcesses}
      />
    </>
  )
}

export default Close
