import React from 'react'
import moment from 'moment'
import MaterialTable from 'material-table'
import { Autocomplete, Skeleton } from '@material-ui/lab'
import { useMutation } from '@tanstack/react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { TextField, Chip, Box, Tooltip, IconButton, TablePagination } from '@material-ui/core'
import * as XLSX from 'xlsx'
import * as api from '../../../api/index'
import queryClient from '../../../api/queryClient'
import { updateVacancyRequest } from '../../../api/index'
import { useAppContext } from '../../../context/appContext'
import { SelectChangeStatusTemplateRequestNotGroup } from '../../molecules'
import { IconCheckCircle, IconCancel, IconDoneAll, XIcon, MTText, FileIcon, ButtonWithIcon, SpinnerIcon } from '../../atoms'
import { LIMITCANDIDATESNUMBER, QUERY_KEYS, ALERT_LEVEL, STATES_TEMPLATE_REQUEST, REASONS_TEMPLATE_REQUEST } from '../../../config/constants'

import i18n from '../../../assets/i18n'

import { TemplateRequestTableStyled } from './styled'
import { exportVacancyRequestCsvOnBackground, vacancyRequestFilterParams } from '../../../api/queries/useVacancyRequestManager'

const ToolbarTable = ({ data, tableColumns, total, showOpenProcesses, rowSelected, tableRef, setRowSelected, loading }) => {
  const { createAlert, filters } = useAppContext()

  const cleanedContextFilters = vacancyRequestFilterParams(filters)

  const clearRowsSelected = () => {
    setRowSelected([])
    tableRef.current && tableRef.current.onAllSelected(false)
  }

  const handleChangeState = async data => {
    rowSelected.map(async row => {
      await api
        .updateNewStatesSelectionCandidate(data, row.id)
        .then(() => {
          createAlert(i18n.phases_states.updatedStatus, ALERT_LEVEL.SUCCESS)
          queryClient.invalidateQueries([QUERY_KEYS.VACANCY_REQUEST])
          clearRowsSelected()
        })
        .catch(err => {
          createAlert(err.message, ALERT_LEVEL.ERROR)
        })
    })
  }

  const handleExportXLSX = () => {
    const selectionIds = rowSelected.map(row => {
      return row.id
    })
    const filtersToReportRequest = JSON.parse(JSON.stringify(cleanedContextFilters))

    if (showOpenProcesses === true && filtersToReportRequest.filter?.state == null) { 
      filtersToReportRequest.filter.state = '["empty", "active", "pending"]'
    } else if (showOpenProcesses === false && filtersToReportRequest.filter?.state == null) { 
      filtersToReportRequest.filter.state = '["closed", "rejected", "cancelled", "inactive"]'
    }

    exportVacancyRequestCsvOnBackground(filtersToReportRequest, selectionIds)
      .then(() => {
        createAlert(i18n.reports.reportExportedSuccess, ALERT_LEVEL.SUCCESS)
      })
      .catch(err => {
        createAlert(i18n.reports.reportExportedFailed, ALERT_LEVEL.ERROR)
      })
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" gridGap="10" mt={2} mb={1}>
        <Box className="headerTable">
          <h5>{`${String(total) === 'undefined' ? '...' : String(total)} ${i18n.templateRequestManager.processOpened}`}</h5>
        </Box>
        <Box display="flex" alignItems="center" gridGap="10">
          {showOpenProcesses && rowSelected.length > 0 && (
            <>
              <Box display="flex" alignItems="center" justifyContent="center" gridGap="10">
                <ButtonWithIcon
                  title={i18n.templateRequestManager.cancelProcess}
                  tooltip={i18n.templateRequestManager.cancelProcessTooltip}
                  Icon={<IconCancel />}
                  onClick={() => handleChangeState({ id: STATES_TEMPLATE_REQUEST.CANCELLED_STATE })}
                  svgFill="#7A808D"
                />
                <ButtonWithIcon
                  title={i18n.templateRequestManager.closeProcess}
                  tooltip={i18n.templateRequestManager.closeProcessTooltip}
                  Icon={<IconDoneAll />}
                  onClick={() => handleChangeState({ id: STATES_TEMPLATE_REQUEST.CLOSED_STATE })}
                  svgFill="#7A808D"
                />
              </Box>
            </>
          )}
          <ButtonWithIcon title={i18n.headerProfileButtons.export} Icon={<FileIcon />} onClick={() => handleExportXLSX()} disabled={total === 0} />
        </Box>
      </Box>
    </>
  )
}

const TemplateRequestTable = ({
  data,
  loading,
  total,
  i18n,
  rowSelected,
  setRowSelected,
  setPage,
  setPerPage,
  page,
  perPage,
  range,
  setRange,
  sort,
  setSort,
  showOpenProcesses,
  type
}) => {
  const { createAlert } = useAppContext()
  const history = useHistory()
  const location = useLocation()

  const isManager = location.pathname.includes('manager')

  const tableRef = React.useRef()
  const [actionDisabled, setActionDisabled] = React.useState(false)
  const [selectedVacancies, setSelectedVacancies] = React.useState({})

  const updateProcess = async data => {
    try {
      await updateVacancyRequest(data)
    } catch (error) {
      console.error('Error while creating process:', error)
    }
  }

  const clearRowsSelected = () => {
    tableRef.current && tableRef.current.onAllSelected(false)
  }

  const mutationUpdateProcess = useMutation(updateProcess)

  const handleChangeVacancies = async (event, newValue, rowData) => {
    event.stopPropagation()

    setSelectedVacancies(prevState => ({
      ...prevState,
      [rowData.id]: newValue.id
    }))

    let data = {
      id: rowData.id,
      vacancies: newValue.id,
      limit_candidates: newValue.id * LIMITCANDIDATESNUMBER
    }

    await mutationUpdateProcess.mutateAsync(data)
    createAlert(i18n.phases_states.updatedVacancies, ALERT_LEVEL.SUCCESS)
    clearRowsSelected()
  }

  const handleChangeAccepts = async (event, rowData) => {
    event.stopPropagation()
    setActionDisabled(true)

    await api
      .updateNewStatesSelectionCandidate({ id: 'active' }, rowData.id)
      .then(() => {
        createAlert(i18n.phases_states.updatedStatus, ALERT_LEVEL.SUCCESS)
        queryClient.invalidateQueries([QUERY_KEYS.VACANCY_REQUEST])
        clearRowsSelected()
        setActionDisabled(false)
      })
      .catch(err => {
        createAlert(err.message, ALERT_LEVEL.ERROR)
      })
  }

  const handleChangeRejects = async (event, rowData) => {
    event.stopPropagation()
    setActionDisabled(true)

    await api
      .updateNewStatesSelectionCandidate({ id: 'rejected' }, rowData.id)
      .then(() => {
        createAlert(i18n.phases_states.updatedStatus, ALERT_LEVEL.SUCCESS)
        queryClient.invalidateQueries([QUERY_KEYS.VACANCY_REQUEST])
        clearRowsSelected()
        setActionDisabled(false)
      })
      .catch(err => {
        createAlert(err.message, ALERT_LEVEL.ERROR)
      })
  }

  const tableColumns = {
    actions: {
      sorting: false,
      hidden: type === 'reviewed',
      render: function StatusProcessColumn(row) {
        return (
          <Box display="flex" alignItems="center" justifyContent="center" gridGap="10">
            <Tooltip title={i18n.templateRequestManager.acceptProcess}>
              {actionDisabled ? (
                <Skeleton variant="rect" width={35} height={10} />
              ) : (
                <IconButton aria-label="accept" onClick={event => handleChangeAccepts(event, row)}>
                  <IconCheckCircle />
                </IconButton>
              )}
            </Tooltip>
            <Tooltip title={i18n.templateRequestManager.rejectProcess}>
              {actionDisabled ? (
                <Skeleton variant="rect" width={35} height={10} />
              ) : (
                <IconButton aria-label="delete" onClick={event => handleChangeRejects(event, row)}>
                  <XIcon stroke="#141C24" width="1" />
                </IconButton>
              )}
            </Tooltip>
          </Box>
        )
      },
      value: 'actions',
      field: 'statusProcess'
    },
    clientPlace: {
      title: i18n.templateRequestManager.workplace,
      render: function UnitColumn(row) {
        return <MTText value={row?.client_place?.name} style={{ fontWeight: '400' }} />
      },
      value: 'client_place.name',
      field: 'clientPlace'
    },
    user: {
      title: i18n.templateRequestManager.requestor,
      render: function UnitColumn(row) {
        return (
          <>
            <MTText value={row?.user?.name + ' ' + row?.user?.surname} style={{ fontWeight: '600', marginBotton: '5px' }} />
            <MTText value={row?.user?.email} style={{ fontWeight: '400', fontSize: '12px' }} />
          </>
        )
      },
      value: 'user.name',
      field: 'user'
    },
    createdAt: {
      title: i18n.templateRequestManager.dateCreated,
      render: function DateCreatedColumn(row) {
        return <MTText value={moment(row?.created_at).format('DD/MM/YYYY')} style={{ fontWeight: '400' }} />
      },
      value: 'created_at',
      field: 'createdAt'
    },
    startDateAt: {
      title: i18n.templateRequestManager.dateIncorporation,
      render: function DateIncorporationColumn(row) {
        return <MTText value={row?.start_date_at ? moment(row?.start_date_at).format('DD/MM/YYYY') : ''} style={{ fontWeight: '400' }} />
      },
      value: 'start_date_at',
      field: 'startDateAt'
    },
    candidatureSettings: {
      title: i18n.templateRequestManager.position,
      render: function DateIncorporationColumn(row) {
        return <MTText value={row?.candidature_settings?.name} style={{ fontWeight: '400' }} />
      },
      value: 'candidature_settings.name',
      field: 'candidatureSettings'
    },
    contractType: {
      title: i18n.templateRequestManager.typeContract,
      render: function TypeContractColumn(row) {
        return (
          <MTText
            value={row?.contract_type == 1 ? i18n.templateRequestManager.vacancyContract[1] : i18n.templateRequestManager.vacancyContract[2]}
            style={{ fontWeight: '400' }}
          />
        )
      },
      value: 'contract_type',
      field: 'contractType'
    },
    vacancies: {
      title: i18n.templateRequestManager.vacancies,
      render: function VacanciesColumn(row) {
        let option = new Array(row.requested_vacancies).fill(0).map((_, index) => ({ id: index + 1, name: `${index + 1}` }))
        return isManager || type === 'reviewed' ? (
          <MTText value={row?.vacancies} style={{ fontWeight: '400' }} />
        ) : (
          <Autocomplete
            size="small"
            className="autocomplete-custom"
            value={option.find(opt => opt.id === (selectedVacancies[row.id] || row.vacancies)) || { id: row.vacancies, name: `${row.vacancies}` }}
            options={option}
            getOptionLabel={option => option.name || ''}
            renderInput={params => <TextField {...params} variant="outlined" disabled={row.vacancies === 1} onClick={event => event.stopPropagation()} />}
            disableClearable={true}
            onChange={(event, newValue) => handleChangeVacancies(event, newValue, row)}
            disabled={option.length === 1}
          />
        )
      },
      value: 'vacancies',
      field: 'vacancies'
    },
    proposedCandidates: {
      title: <span style={{ fontWeight: 'bold', color: 'black' }}>{ i18n.templateRequestManager.proposedCandidates }</span>,
      render: function ProposedCandidatesColumn(row) {
        return <MTText value={row.proposed_candidates} style={{ fontWeight: '400' }} />
      },
      value: 'proposed_candidates',
      field: 'limitCandidates',
      sorting: false,
    },
    interviewed_candidates: {
      title: i18n.templateRequestManager.interviewedCandidates,
      render: function InterviewedCandidatesColumn(row) {
        return <MTText value={row.interviewed_candidates || '0'} style={{ fontWeight: '400' }} />
      },
      value: 'interviewed_candidates',
      field: 'interviewed_candidates'
    },
    state: {
      title: i18n.templateRequestManager.statusProcess,
      render: function StatusProcessColumn(row) {
        let color = ''
        switch (row.state) {
          case 'active':
            color = '#D1E5FC'
            break
          case 'closed':
            color = '#D8DDE3'
            break
          case 'inactive':
            color = '#cbcec4'
            break
          case 'rejected':
            color = '#FF6961'
            break
          case 'empty':
            color = '#A699FA'
            break
          case 'pending':
            color = '#FFCAA2'
            break
          case 'cancelled':
            color = '#FFA2A2'
            break
          default:
            color = '#77DD77'
            break
        }

        return (
          <div style={{ textAlign: 'left' }}>
            <Chip
              label={i18n.templateRequestManager.vacancyStates[row.state]}
              style={{ backgroundColor: color, minWidth: '80px', height: '20px', fontSize: '13px', textAlign: 'left' }}
            />
          </div>
        )
      },
      value: 'state',
      field: 'state'
    },
    reason: {
      title: i18n.templateRequestManager.reason,
      render: function StatusProcessColumn(row) {
        let color = ''
        switch (row.reason) {
          case REASONS_TEMPLATE_REQUEST.GAP:
            color = '#77DD77'
            break
          case REASONS_TEMPLATE_REQUEST.REPLACEMENT:
            color = '#FFCAA2'
            break
        }

        return row.reason && (
          <div style={{ textAlign: 'left' }}>
            <Chip
              label={i18n.templateRequestManager.vacancyReasons[row.reason]}
              style={{ backgroundColor: color, minWidth: '80px', height: '20px', fontSize: '13px', textAlign: 'left' }}
            />
          </div>
        )
      },
      value: 'state',
      field: 'state'
    },
    lastDisplayDateAt: {
      sorting: false,
      title: <span style={{ fontWeight: 'bold', color: 'black' }}>{ i18n.templateRequestManager.lastDisplayDateAt }</span>,
      render: function PrintDate(row) {
        let date = row?.metric_map?.vr_last_display_date_at ? moment(row?.metric_map?.vr_last_display_date_at) : null
        return date && (<MTText value={date ? date.format('DD/MM/YYYY HH:mm') : ''} style={{ fontWeight: '400' }} />)
      },
      value: 'metric_map.vr_last_display_date_at',
      field: 'lastDisplayDateAt'
    },
    lastCandidatureChangeStatusDateAt: {
      sorting: false,
      title: <span style={{ fontWeight: 'bold', color: 'black' }}>{ i18n.templateRequestManager.lastCandidatureChangeStatusDateAt }</span>,
      render: function PrintDate(row) {
        let date = row?.metric_map?.vr_last_candidature_change_status_at ? moment(row?.metric_map?.vr_last_candidature_change_status_at) : null
        return date && (<MTText value={date ? date.format('DD/MM/YYYY HH:mm') : ''} style={{ fontWeight: '400' }} />)
      },
      value: 'metric_map.vr_last_candidature_change_status_at',
      field: 'lastCandidatureChangeStatusDateAt'
    },
  }

  const tableOptions = {
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    pageSize: perPage || 20,
    pageSizeOptions: [5, 10, 20, 50, 100],
    cellStyle: { textAlign: 'center' },
    toolbar: false,
    draggable: false,
    selection: type !== 'reviewed'
  }

  let columnsArray = []

  if (isManager) {
    delete tableColumns.actions
    columnsArray = Object.values(tableColumns)
  } else {
    delete tableColumns.proposedCandidates
    delete tableColumns.interviewedCandidates
    delete tableColumns.statusProcess
    columnsArray = Object.values(tableColumns)
  }

  const handleRowClick = (e, row) => {
    history.push(`/talent_acquisition/template-request-manager/${row.id}`)
  }

  const checkRow = data => {
    setRowSelected(data)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangePerPage = event => {
    setPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeOrder = position => {
    if (position === -1) return
    setPage(0)
    sort[0] === columnsArray[position]?.field && sort[1] === 'asc'
      ? setSort([columnsArray[position]?.field, 'desc'])
      : setSort([columnsArray[position]?.field, 'asc'])
  }

  if (loading) return <SpinnerIcon />

  return (
    <TemplateRequestTableStyled>
      <ToolbarTable
        tableRef={tableRef}
        data={data}
        tableColumns={tableColumns}
        total={total}
        showOpenProcesses={showOpenProcesses}
        rowSelected={rowSelected}
        setRowSelected={setRowSelected}
        loading={loading}
      />
      <MaterialTable
        tableRef={tableRef}
        data={data}
        columns={columnsArray}
        options={tableOptions}
        onRowClick={handleRowClick}
        onSelectionChange={checkRow}
        onOrderChange={handleChangeOrder}
        localization={{
          body: {
            emptyDataSourceMessage: i18n.templateRequestManager.emptyData
          }
        }}
        components={{
          Pagination: () => (
            <TablePagination
              component="div"
              rowsPerPageOptions={[5, 10, 20, 50, 100]}
              rowsPerPage={perPage}
              showFirstButton={true}
              showLastButton={true}
              count={total}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangePerPage}
              labelRowsPerPage={i18n.templateRequestManager.rowsPerPage}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${i18n.templateRequestManager.of} ${count}`}
            />
          )
        }}
      />
    </TemplateRequestTableStyled>
  )
}

export default TemplateRequestTable
