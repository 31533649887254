import { configure, configureAxiosDefaults, get, getList, patch, post, put, remove } from './utils'
import StatusGroupType from '../models/status-group-type'
import _ from 'lodash'
import axios from 'axios'
import * as ApiConfig from '../config/api'
import * as FiltersUtils from '../utils/filters'
import { MODULES, QUERY_KEYS } from '../config/constants'
import { replaceUrlTextByHtmlLink } from '../utils/url'
import eventTracker from '../utils/eventTracker'
import StatusType from '../models/status-type'
import moment from 'moment-timezone'

/* CONFIG */
export const configureInterceptors = errorAuthCallback => configure(errorAuthCallback)
export const configureToken = token => configureAxiosDefaults('Authorization', 'Bearer ' + token)

/* UTILS */
export const getQueryData = query => {
  const range = getRange(query.page, query.pageSize)
  const orderDirection = query.orderDirection ? query.orderDirection : ''
  const orderBy = query.orderBy ? query.orderBy.field : ''

  return { range, sort: [orderBy, orderDirection], page: query.page, pageSize: query.pageSize }
}

export const getRange = (page, perPage) => {
  const initialRange = (page === 0 ? 0 : page) * perPage
  const finishRange = initialRange + perPage - 1
  return [initialRange, finishRange]
}

/* AUTH */
export const sigIn = ({ username, password }) => post(`/authentication_token`, { username, password })
export const recoverPassword = email => post('/public/password-recovery', { email })

/* ME */
export const getMeProfile = () => get('/api/self')
export const putSelf = data => put('/api/self', data)
export const getMeClient = () => get('/api/self/client')
export const changePassword = ({ oldPass, newPass }) => patch('/api/self/change_password', { old_password: oldPass, new_password: newPass })

/* INBOX */
export const getInboxData = (filters, rangeArr, sort, module = MODULES.ID_TALENT_ACQUISITION) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  const range = rangeArr ? encodeURI(JSON.stringify(rangeArr)) : ''
  sort = sort ? encodeURI(JSON.stringify(sort)) : ''

  return get(`/api/module/${module}/inbox?filter=${filters}&range=${range}${sort ? `&sort=${sort}` : ''}`).then(data => {
    data.data = addItemCount(data.data, rangeArr)
    return data
  })
}
export const getInboxCount = (filters, module = MODULES.ID_TALENT_ACQUISITION) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  return get(`/api/module/${module}/inbox-count?filter=${filters}`)
}
export const updateInterviewerNotes = (data, module = MODULES.ID_TALENT_ACQUISITION) => {
  eventTracker.track('UpdateInterviewerNotes', {
    candidate: data.candidate,
    note: data.note.split('-*{}*-')[0]
  })
  return put(`/api/module/${module}/inbox/set_interviewer_notes`, data)
}

export const addItemCount = (rows, rangeArr) => {
  return _.map(rows, (row, index) => {
    row.itemCount = (rangeArr && rangeArr[0] ? rangeArr[0] : 0) + index + 1
    return row
  })
}

export const getStatusTypes = (module = MODULES.ID_TALENT_ACQUISITION) => get(`api/module/${module}/status_types`)
export const getTagsTypes = (module = MODULES.ID_TALENT_ACQUISITION) => get(`api/module/${module}/tags_types`)

const getInboxByStatusTotals = async (filters, statusList) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  const response = await getInboxData({ ...filters, estado: statusList })
  return response.total
}

export const getInboxByStatusTotalsGrouped = async filters => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  const toManageTotal = await getInboxByStatusTotals(filters, StatusGroupType.TO_MANAGE.status)
  const inProgressTotal = await getInboxByStatusTotals(filters, StatusGroupType.IN_PROGRESS.status)
  const finalistsTotal = await getInboxByStatusTotals(filters, StatusGroupType.FINALISTS.status)

  const values = {
    [StatusGroupType.TO_MANAGE.id]: toManageTotal,
    [StatusGroupType.IN_PROGRESS.id]: inProgressTotal,
    [StatusGroupType.FINALISTS.id]: finalistsTotal
  }

  let total = 0
  _.forEach(values, value => (total = total + value))
  return { values, total }
}

export const getInboxByStatusArrayTotalsGrouped = async (filters, states) => {
  let total = 0

  const values = {
    [StatusGroupType.TO_MANAGE.id]: 0,
    [StatusGroupType.IN_PROGRESS.id]: 0,
    [StatusGroupType.FINALISTS.id]: 0
  }

  const responses = await Promise.all(_.map(states, state => getInboxByStatusTotals(filters, [state])))

  // Por cada estado en el filtro, obtener el total
  // Por cada total, ir añadiendo al grupo que corersponde
  _.forEach(responses, (rTotal, index) => {
    const state = states[index]

    let sgtId = -1
    _.forEach(StatusGroupType, sgt => {
      if (_.find(sgt.status, value => value === state)) {
        sgtId = sgt.id
      }
    })

    if (sgtId !== -1) {
      values[sgtId] = values[sgtId] + rTotal
      total = total + rTotal
    }
  })

  return { values, total }
}

export const getInboxHeaders = (module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/inbox_fields`)
export const getInboxFilters = (module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/filters`)

export const getInboxItem = (id, module = MODULES.ID_TALENT_ACQUISITION) => {
  return get(`/api/module/${module}/inbox/${id}`)
}
export const deleteInboxCandidature = (candidatureId, module) => {
  eventTracker.track('CandidateDeleted', {
    candidateId: candidatureId,
    module: module
  })

  return remove(`/api/module/${module}/inbox/${candidatureId}`)
}
export const deleteInboxCandidatureQuery = ({ candidatureId, module }) => {
  eventTracker.track('CandidateDeleted', {
    candidateId: candidatureId,
    module: module
  })

  return remove(`/api/module/${module}/inbox/${candidatureId}`)
}

export const getReads = () => get('/api/inbox/reads')
export const postReads = (ids, module = MODULES.ID_TALENT_ACQUISITION) => post(`/api/module/${module}/reads/add`, { ids })
export const deleteReads = (ids, module = MODULES.ID_TALENT_ACQUISITION) => post(`/api/module/${module}/reads/remove`, { ids })
// TODO: unificar tras implementar react-query en todo el proyecto
export const postReads_query = ({ ids, module = MODULES.ID_TALENT_ACQUISITION }) => post(`/api/module/${module}/reads/add`, { ids })
export const deleteReads_query = ({ ids, module = MODULES.ID_TALENT_ACQUISITION }) => post(`/api/module/${module}/reads/remove`, { ids })

const ignoredChangeStatus = [StatusType.CITED, StatusType.TO_BE_CONFIRMED]
const isNotIgnorableStatusEmit = statusId => {
  return !ignoredChangeStatus.includes(parseInt(statusId))
}

export const changeStatus = (data, module = MODULES.ID_TALENT_ACQUISITION) => {
  if (isNotIgnorableStatusEmit(data.status_id)) {
    eventTracker.track('ChangeStatus', {
      date: data.date,
      id: data.id,
      status: data.status_id,
      module: module
    })
  }
  return put(`/api/module/${module}/inbox/change_status`, data)
}

export const changeStatus_query = ({ data, module = MODULES.ID_TALENT_ACQUISITION }) => {
  _.map(data, item => {
    if (isNotIgnorableStatusEmit(item.status_id)) {
      eventTracker.track('ChangeStatus', {
        date: item.date,
        id: item.id,
        status: item.status_id,
        module: module
      })
    }
  })

  return put(`/api/module/${module}/inbox/change_status`, data)
}

export const postTag = data => {
  return post(`/api/tags`, data)
}

export const patchTag = data => {
  return patch(`/api/tags`, data)
}

export const deleteTag = data => {
  return remove(`/api/tags`, data)
}

export const isTagInUse = id => {
  return get(`/api/tags/${id}/in_use`)
}

export const changeTags = (ids, tags, module = MODULES.ID_TALENT_ACQUISITION) => {
  eventTracker.track('ChangeTags', {
    id: ids,
    tags: tags,
    module: module
  })
  return post(`/api/module/${module}/tags`, { ids, tags })
}

export const changeTags_query = ({ ids, tags, module = MODULES.ID_TALENT_ACQUISITION }) => {
  eventTracker.track('ChangeTags', {
    id: ids,
    tags: tags,
    module: module
  })

  return post(`/api/module/${module}/tags`, { ids, tags })
}
export const getInboxCsv = (fields, filters, sort, module = MODULES.ID_TALENT_ACQUISITION) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  const queryfilters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  const querySort = sort ? encodeURI(JSON.stringify(sort)) : ''
  const queryFields = fields ? encodeURI(JSON.stringify(fields)) : ''
  let ep = `/api/module/${module}/inbox/export?report_fields=${queryFields}&filter=${queryfilters}&sort=${querySort}`
  return get(ep, { responseType: 'arraybuffer' })
}
export const getInboxCsvOnBackground = (fields, filters, sort, module = MODULES.ID_TALENT_ACQUISITION, isPlanning = false, receivers) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  const queryfilters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  const querySort = sort ? encodeURI(JSON.stringify(sort)) : ''
  const queryFields = fields ? encodeURI(JSON.stringify(fields)) : ''
  let ep = `/api/module/${module}/inbox/background-export?report_fields=${queryFields}&filter=${queryfilters}&sort=${querySort}${
    isPlanning ? '&is_planning=1' : ''
  }&receivers=${receivers}`
  return get(ep)
}
export const postOffer = obj => {
  obj.filters = JSON.stringify(FiltersUtils.parseFiltersFromFrontToApiFormat(obj.filters))

  return post(`/api/module/${MODULES.ID_TALENT_ACQUISITION}/notices/offers`, obj)
}

export const requireDoc = ({ ...data }, module = MODULES.ID_TALENT_ACQUISITION) => {
  data.message = replaceUrlTextByHtmlLink(data.message)
  return put(`/api/module/${module}/inbox/doc_required`, data)
}
export const getRequireDocByNotification = (notificationId, candidatureId, module = MODULES.ID_TALENT_ACQUISITION) =>
  axios.get(`${ApiConfig.BASE_URL}/api/public/module/${module}/doc-required?notification=${notificationId}&candidature=${candidatureId}`)
export const getRequireDocPersonByNotification = (notificationId, personId, module = MODULES.ID_TALENT_ACQUISITION) =>
  axios.get(`${ApiConfig.BASE_URL}/api/public/module/${module}/doc-required-person?notification=${notificationId}&person=${personId}`)
export const patchRequireDocByNotification = (notificationId, candidatureId, files, module = MODULES.ID_TALENT_ACQUISITION) =>
  axios.patch(`${ApiConfig.BASE_URL}/api/public/module/${module}/doc-required?notification=${notificationId}&candidature=${candidatureId}`, { files })
export const requireDocQuery = ({ data, ids, module = MODULES.ID_TALENT_ACQUISITION, type = 'candidature' }) => {
  data.message = replaceUrlTextByHtmlLink(data.message)
  return put(`/api/module/${module}/inbox/doc_required_batch`, { data, ids, type })
}

/* OBSERVATIONS */
export const getTechAvatar = (id, module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/observations/technicalSelection/${id}/avatar`)
export const getUserObservations = (id, module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/identifier/${id}/observations`)
export const postUserObservations = (id, change_msg, module = MODULES.ID_TALENT_ACQUISITION) =>
  post(`/api/module/${module}/identifier/${id}/observations`, { id, change_msg })
export const postUserObservationsQuery = ({ id, change_msg, module = MODULES.ID_TALENT_ACQUISITION, type = 'candidature' }) =>
  post(`/api/module/${module}/identifier/${id}/observations`, { id, change_msg, type })

/* CITATIONS */
export const getCitations = () => get(`/api/citations/v2`)
export const getCitationWhatsappCounter = filters => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''

  return get(`/api/citations/whatsapp_counter?filter=${filters}`)
}
export const getCitationsData = (filtersOriginal, rangeArr, sort) => {
  let filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filtersOriginal)

  const sortField = _.get(sort, '[0]', '')

  if (sortField === 'availability') {
    sort[0] = 'availability.position'
  } else if (sortField === 'not_interested') {
    sort[0] = 'not_interested.position'
  }

  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  let range = rangeArr ? encodeURI(JSON.stringify(rangeArr)) : ''

  let ep = `/api/citations/v2?filter=${filters}&range=${range}`
  if (_.get(sort, '[0]') && _.get(sort, '[1]')) {
    sort = sort ? encodeURI(JSON.stringify(sort)) : ''
    ep = `${ep}&sort=${sort}`
  }
  return getList(ep).then(({ total, data }) => {
    data = addItemCount(data, rangeArr)
    return { data, total }
  })
}
export const postCitation = ({ ...data }) => {
  data.interviewer = replaceUrlTextByHtmlLink(data.interviewer)
  data.message = replaceUrlTextByHtmlLink(data.message)
  data.location = replaceUrlTextByHtmlLink(data.location)

  eventTracker.track('CitationEvent', {
    data: data
  })

  return post(`/api/citations`, data)
}
export const postOrPatchCitations = ({ data, ids }) => {
  data.message = replaceUrlTextByHtmlLink(data.message)
  data.location = replaceUrlTextByHtmlLink(data.location)

  const guessedTimezone = moment.tz.guess()
  data.timezone = guessedTimezone
  // data.timezone = 'Europe/London';

  eventTracker.track('CitationEvent', {
    ids: ids,
    data: data
  })

  return post(`/api/citations/post-patch`, { data, ids })
}
export const patchCitation = ({ id, ...data }) => {
  return patch(`/api/citations/${id}`, data)
}
export const getEditCitationsCounter = ids => {
  return post(`/api/citations/get_edit_counter`, ids)
}
export const getCitationsResponsible = () => get(`/api/citations/responsible`)
export const getCitationDataByCitationId = (citationId, pin, application) =>
  axios.get(`${ApiConfig.BASE_URL}/api/public/citation/${citationId}/pin/${pin}/application/${application}`)

export const getCitationsDataQuery = ({ filtersOriginal, rangeArr, sort }) => {
  let filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filtersOriginal)

  const sortField = _.get(sort, '[0]', '')

  if (sortField === 'availability') {
    sort[0] = 'availability.position'
  } else if (sortField === 'not_interested') {
    sort[0] = 'not_interested.position'
  }

  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  let range = rangeArr ? encodeURI(JSON.stringify(rangeArr)) : ''

  // let ep = `/api/citations?filter=${filters}&range=${range}`
  let ep = `/api/citations/v2?filter=${filters}&range=${range}`
  if (_.get(sort, '[0]') && _.get(sort, '[1]')) {
    sort = sort ? encodeURI(JSON.stringify(sort)) : ''
    ep = `${ep}&sort=${sort}`
  }
  return getList(ep).then(({ total, data }) => {
    data = addItemCount(data, rangeArr)
    return { data, total }
  })
}

export const getMeetingDetails = eventId => get(`/api/citations/getMeetingDetails/${eventId}`)

export const getOfferByNotification = (notificationId, offerId, candidatureId, pin) =>
  axios.get(
    `${ApiConfig.BASE_URL}/api/public/module/${MODULES.ID_TALENT_ACQUISITION}/notices/offers/${offerId}?notification=${notificationId}&candidature=${candidatureId}&pin=${pin}`
  )
export const patchOfferByNotification = (notificationId, offerId, candidatureId, pin, state) =>
  axios.patch(
    `${ApiConfig.BASE_URL}/api/public/module/${MODULES.ID_TALENT_ACQUISITION}/notices/offers/${offerId}?notification=${notificationId}&candidature=${candidatureId}&pin=${pin}`,
    { state }
  )

export const getPullByNotification = (notificationId, pullId, candidatureId, pin) =>
  axios.get(
    `${ApiConfig.BASE_URL}/api/public/module/${MODULES.ID_TALENT_ACQUISITION}/notices/pulls/${pullId}?notification=${notificationId}&candidature=${candidatureId}&pin=${pin}`
  )
export const patchPullByNotification = (notificationId, pullId, candidatureId, pin, interested) =>
  axios.patch(
    `${ApiConfig.BASE_URL}/api/public/module/${MODULES.ID_TALENT_ACQUISITION}/notices/pulls/${pullId}?notification=${notificationId}&candidature=${candidatureId}&pin=${pin}`,
    { interested }
  )

/* Mail Custom */
export const postMailCustom = (id, subject, message, module = MODULES.ID_TALENT_ACQUISITION) =>
  post(`/api/module/${module}/inbox/${id}/custom_mail`, { subject, message })

/* FAVORITES */
export const getFavorites = (filters, rangeArr, sort, module = MODULES.ID_TALENT_ACQUISITION) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  let range = rangeArr ? encodeURI(JSON.stringify(rangeArr)) : ''
  sort = sort ? encodeURI(JSON.stringify(sort)) : ''
  return get(`/api/module/${module}/favourites?filter=${filters}&range=${range}${sort ? `&sort=${sort}` : ''}`).then(data => {
    data.data = addItemCount(data.data, rangeArr)
    return data
  })
}
export const postFavorites = (ids, module = MODULES.ID_TALENT_ACQUISITION) => post(`/api/module/${module}/favourites/add`, { ids })
export const deleteFavorites = (ids, module = MODULES.ID_TALENT_ACQUISITION) => post(`/api/module/${module}/favourites/remove`, { ids })

// TODO: just use one method for favorites when react-query is fully implemented
export const postFavorites_query = ({ ids, module = MODULES.ID_TALENT_ACQUISITION }) => post(`/api/module/${module}/favourites/add`, { ids })
export const deleteFavorites_query = ({ ids, module = MODULES.ID_TALENT_ACQUISITION }) => post(`/api/module/${module}/favourites/remove`, { ids })
export const postState_query = data => post(`/api/vacancy/candidature/change-status`, data)

export const deleteSelectedAttachmentsQuery = ({ entityId, files, module = MODULES.ID_TALENT_ACQUISITION }) => remove(`/api/module/${module}/inbox/${entityId}/files`, { files: files })

/* SAVED SEARCHS */
export const getSaveSearches = (sort, module = MODULES.ID_TALENT_ACQUISITION) => {
  sort = sort ? encodeURI(JSON.stringify(sort)) : ''
  let range = encodeURI(JSON.stringify([0, 49]))

  return get(`/api/module/${module}/saved_searchs?${sort ? `&sort=${sort}` : ''}&range=${range}`)
}
export const getSaveSearchData = (id, module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/saved_searchs/${id}`)

export const postSaveSearches = ({ title, date, fields }, module = MODULES.ID_TALENT_ACQUISITION) => {
  fields = FiltersUtils.parseFiltersFromFrontToApiFormat(fields, false)
  fields = fields ? JSON.stringify(fields) : ''

  eventTracker.track('SavedSearchCreated', {
    title: title,
    date: date,
    fields: fields
  })

  return post(`/api/module/${module}/saved_searchs`, { title, date, fields })
}
export const deleteSaveSearches = (ids, module = MODULES.ID_TALENT_ACQUISITION) => remove(`/api/module/${module}/saved_searchs/${ids}`)
export const deleteSaveSearchesInBatch = ({ ids, module = MODULES.ID_TALENT_ACQUISITION }) => {
  return post(`/api/module/${module}/saved_searchs/batch_delete`, { ids })
}

/* NEW STATES SELECTION FUNNEL */
export const updateNewStatesSelectionFunnel = (data, module = MODULES.ID_TALENT_ACQUISITION) => {
  let type =
    module === MODULES.ID_TALENT_ACQUISITION && data.length
      ? data[0].type === QUERY_KEYS.JOB_OFFER_APPLICATIONS
        ? QUERY_KEYS.JOB_OFFER_APPLICATIONS
        : 'candidatures'
      : null

  eventTracker.track('ChangeFunnelStatus', {
    date: data.date,
    id: data.id,
    status: data.status_id,
    module: module,
    type
  })
  return put(`/api/module/${module}/inbox/change_status_funnel`, data)
}

/* NEW STATES SELECTION CANDIDATE */
export const updateNewStatesSelectionCandidate = (data, row) => {
  return patch(`/api/vacancy/selection/${row}/${data.id}`)
}

export const repushCandidatesOfVacancyRequest = (vacancyRequestId) => {
  return patch(`/api/vacancy/selection/${vacancyRequestId}/repush`)
}

/* DEFAULT MESSAGES */
export const getDefaultMessages = (module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/default_messages`)

/* PUBLIC */
export const getCandidaturesByClientAndDni = (clientId, dni) => {
  return axios.get(`${ApiConfig.BASE_URL}/api/public/client/${clientId}/${dni}/candidature/Any`)
}

export const updateCitationStatus = params => {
  const citationId = _.get(params, 'citationId')
  const statusId = _.get(params, 'statusId')
  const pin = _.get(params, 'pin')
  const application = _.get(params, 'application')
  return axios.get(`${ApiConfig.BASE_URL}/api/public/citations/${citationId}/status/${statusId}?pin=${pin}&application=${application}`)
}

export const getRescheduleAvailability = params => {
  const citationId = _.get(params, 'citationId')
  const pin = _.get(params, 'pin')
  const application = _.get(params, 'application')
  return axios.get(`${ApiConfig.BASE_URL}/api/public/citations/availability/tags/${citationId}/${pin}/${application}`)
}

export const addRescheduleAvailability = params => {
  const citationId = _.get(params, 'citationId')
  const pin = _.get(params, 'pin')
  const application = _.get(params, 'application')
  const availabilityId = _.get(params, 'availabilityId')
  const reason = _.get(params, 'reason')
  return axios.put(`${ApiConfig.BASE_URL}/api/public/citations/availability/${citationId}/${pin}/${application}/${availabilityId}`, {
    reason: reason
  })
}

export const getNotInterestedReason = params => {
  const citationId = _.get(params, 'citationId')
  const pin = _.get(params, 'pin')
  const application = _.get(params, 'application')
  return axios.get(`${ApiConfig.BASE_URL}/api/public/citations/notInterested/tags/${citationId}/${pin}/${application}`)
}

export const addNotInterestedReason = params => {
  const citationId = _.get(params, 'citationId')
  const pin = _.get(params, 'pin')
  const application = _.get(params, 'application')
  const notInterestedId = _.get(params, 'notInterestedId')
  return axios.put(`${ApiConfig.BASE_URL}/api/public/citations/notInterested/${citationId}/${pin}/${application}/${notInterestedId}`)
}

/* REPORTS */
export const getReports = (module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/reports`)
export const getReportFields = (module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/report_fields`)
export const postReport = (data, module = MODULES.ID_TALENT_ACQUISITION) => {
  eventTracker.track('ReportCreated', {
    from: data.date_from,
    fields: data.fields,
    title: data.title,
    module: module
  })
  return post(`/api/module/${module}/reports`, data)
}
export const putReport = (reportId, data, module = MODULES.ID_TALENT_ACQUISITION) => put(`/api/module/${module}/reports/${reportId}`, data)
export const deleteReport = (reportId, module = MODULES.ID_TALENT_ACQUISITION) => {
  eventTracker.track('ReportDeleted', {
    id: reportId
  })
  return remove(`/api/module/${module}/reports/${reportId}`)
}
export const getReport = (reportId, module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/reports/${reportId}`)
export const getReportOnBackground = (reportId, module, receivers = MODULES.ID_TALENT_ACQUISITION) =>
  get(`/api/module/${module}/reports/${reportId}/background-export?receivers=${receivers}`)
export const getReportCsv = (reportId, module = MODULES.ID_TALENT_ACQUISITION) =>
  get(`/api/module/${module}/reports/${reportId}/export`, { responseType: 'arraybuffer' })

export const getReportsData = (filters, range, sort, module = MODULES.ID_TALENT_ACQUISITION) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  range = range ? encodeURI(JSON.stringify(range)) : ''

  let ep = `/api/module/${module}/reports?filter=${filters}&range=${range}`
  if (_.get(sort, '[0]') && _.get(sort, '[1]')) {
    sort = sort ? encodeURI(JSON.stringify(sort)) : ''
    ep = `${ep}&sort=${sort}`
  }
  return getList(ep)
}

/* STATISTICS */
export const getGlobalStatistics = ({ from = '', to = '' }, module = MODULES.ID_TALENT_ACQUISITION, filtersActive) => {
  return get(`/api/module/${module}/statistics/globals?from=${from}&to=${to}&filter=` + encodeURIComponent(JSON.stringify(filtersActive)))
}
export const getPositionStatistics = ({ from = '', to = '' }, module = MODULES.ID_TALENT_ACQUISITION, filtersActive) =>
  get(`/api/module/${module}/statistics/position?from=${from}&to=${to}&filter=` + encodeURIComponent(JSON.stringify(filtersActive)))

export const getFiltersStatistics = (module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/statistics/filters`)

/* PROCESSES */

/* PUSH_OFFERS */
export const getPushOffers = (module = MODULES.ID_TALENT_ACQUISITION, rangeArr, filters, showMyPushOffers) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  let range = rangeArr ? encodeURI(JSON.stringify(rangeArr)) : ''
  let ep = `/api/module/${module}/notices/offers?filter=${filters}&range=${range}&only_mine=${showMyPushOffers}`

  return getList(ep).then(({ total, data }) => {
    data = addItemCount(data, rangeArr)
    return { data, total }
  })
}
export const getPushOfferById = (id, module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/notices/offer/${id}`)
export const getPushOffersByCandidature = (id, module = MODULES.ID_TALENT_ACQUISITION) => get(`/api/module/${module}/identifier/${id}/push_offers`)
export const deletePushOffer = (offerId, module = MODULES.ID_TALENT_ACQUISITION) => remove(`/api/module/${module}/notices/offers/${offerId}`)
export const getPushOfferWhatsappCounter = (module, filters, showMyPushOffers) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  return get(`/api/module/${module}/push_offers/counter?filter=${filters}&only_mine=${showMyPushOffers}`)
}

/* PULL_CONFIGS */
export const getPullConfigs = (userId, filters = {}, rangeArr = [0, 1000], module = MODULES.ID_TALENT_ACQUISITION) => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  let ep = `/api/module/${module}/notices/pulls/user/${userId}?filter=${filters}`

  return getList(ep).then(({ total, data }) => {
    data = addItemCount(data, rangeArr)
    return { data, total }
  })
}
export const getPullConfigsWhatsappCounter = filters => {
  filters = FiltersUtils.parseFiltersFromFrontToApiFormat(filters)
  filters = filters ? encodeURIComponent(JSON.stringify(filters)) : ''
  return get(`/api/module/talent_acquisition/pull_config/counter?filter=${filters}`)
}

/* UPDATE CANDIDATURE */
export const updateCandidature = (data, client, module = MODULES.ID_TALENT_ACQUISITION) => patch(`/api/module/${module}/client/${client}/bot_data`, data)

/* BLACKLIST */
export const addToBlacklist = ({ data, module = MODULES.ID_TALENT_ACQUISITION }) => {
  let payload = { add: data }
  return post(`/api/module/${module}/blacklist`, payload)
}

export const removeFromBlacklist = ({ data, module = MODULES.ID_TALENT_ACQUISITION }) => {
  let payload = { del: data }
  return post(`/api/module/${module}/blacklist`, payload)
}

export const integrateWithTeams = () => {
  return get(`/auth_microsoft`)
}

export const disconnectIntegration = (type, id) => {
  return get(`/meeting_integration/disconnect?type=${type}&userId=${id}`)
}

/* PHASES */
export const postPhase = (data, module = MODULES.ID_TALENT_ACQUISITION) => {
  let postData = {
    ...data,
    type: data.type,
    order: data.order,
    color: data.color
  }
  return post(`/api/module/${module}/phases`, postData)
}

export const patchPhaseOrder = (data, module = MODULES.ID_TALENT_ACQUISITION) => {
  return patch(`/api/module/${module}/phases/all_order`, data)
}

export const patchPhase = (id, data, module = MODULES.ID_TALENT_ACQUISITION) => {
  return patch(`/api/module/${module}/phase/${id}`, data)
}

export const deletePhase = (id, module = MODULES.ID_TALENT_ACQUISITION) => {
  return remove(`/api/module/${module}/phase/${id}`)
}

/* STATES */
export const postState = (id, data, module = MODULES.ID_TALENT_ACQUISITION) => {
  let postData = {
    ...data,
    type: data.type,
    order: data.order,
    phase: id,
    color: data.color,
    description: data.description,
    notification_methods: data.notification_methods
  }
  return post(`/api/module/${module}/phase/${id}/status`, postData)
}

export const patchState = (id, phaseId, data, module = MODULES.ID_TALENT_ACQUISITION) => {
  return patch(`/api/module/${module}/phase/${phaseId}/status/${id}`, data)
}

export const patchStateOrder = (data, module = MODULES.ID_TALENT_ACQUISITION) => {
  return patch(`/api/module/${module}/status/all_order`, data)
}

export const deleteState = ({ id, phaseId, module = MODULES.ID_TALENT_ACQUISITION }) => {
  return remove(`/api/module/${module}/phase/${phaseId}/status/${id}`)
}

export const moveCandidatesFromStateTo = ({ data, module = MODULES.ID_TALENT_ACQUISITION }) => {
  return patch(`/api/module/${module}/inbox/move_candidates_to_funnel_status`, data)
}

/* EVALUATIVE TESTS */
export const checkCsvCandidates = (id, module = MODULES.ID_TALENT_ACQUISITION, data) => {
  return post(`/api/module/${module}/evaluative-feature/csv-checker/${id}`, data, {
    'Content-Type': 'multipart/form-data'
  })
}
export const importCsvCandidates = (id, module = MODULES.ID_TALENT_ACQUISITION, data) => {
  return post(`/api/module/${module}/evaluative-feature/csv-import/${id}`, data, {
    'Content-Type': 'multipart/form-data'
  })
}

export const postEvaluativeTests = (data, module = MODULES.ID_TALENT_ACQUISITION) => {
  let postTest = {
    ...data,
    name: data.name,
    init_date: data.init_date,
    end_date: data.end_date,
    bot_url: data.bot_url,
    questions: data.questions,
    evaluative_config_template: data.evaluative_config_template || null
  }
  return post(`/api/module/${module}/evaluative-feature`, postTest)
}

export const duplicateEvaluativeTest = (id, module = MODULES.ID_TALENT_ACQUISITION) => {
  return post(`/api/module/${module}/evaluative-feature/${id}`)
}

export const editEvaluativeTest = (id, data, module = MODULES.ID_TALENT_ACQUISITION) => {
  let editTest = {
    ...data,
    name: data.name || '',
    init_date: data.init_date || '',
    end_date: data.end_date || '',
    bot_url: data.bot_url || '',
    questions: data.questions || [],
    evaluative_config_template: data.evaluative_config_template || null
  }

  return put(`/api/module/${module}/evaluative-feature/${id}`, editTest)
}

export const deleteEvaluativeTest = (id, module = MODULES.ID_TALENT_ACQUISITION) => {
  return remove(`/api/module/${module}/evaluative-feature/${id}`)
}

export const changeVideoInterviewStatus = ({ id, status, candidateId }, module = MODULES.ID_TALENT_ACQUISITION) => {
  return post(`/api/module/${module}/evaluative-feature/change-status/${id}/${candidateId}`, { status })
}

/* USER MANAGAMENT */

export const postUsers = data => {
  return post(`/api/users`, data)
}

export const patchUser = (id, data) => {
  return patch(`/api/users/${id}`, data)
}

export const deleteUser = id => {
  return remove(`/api/users/${id}`)
}

export const getClientSections = () => {
  return get(`/api/client/sections_list`)
}

/* INTEGRATIONS USER / KEYWORDS RPA */

export const postRpaIntegration = (data, client_id) => {
  let postData = {
    candidature: data.candidature,
    provider: data.provider && data.provider.default === 'pandape' ? data.provider.priority : data.provider.default,
    concat_url: data.concat_url,
    user: data.user,
    password: data.password,
    keyword: data.keyword,
    login_url: data.login_url
  }

  return post(`/api/rpa/${client_id}`, postData)
}

export const patchRpaIntegration = (id, client_id, data) => {
  let editData = {
    ...data,
    user: data.user,
    password: data.password,
    active: data.active
  }

  return patch(`/api/rpa/${client_id}/${id}`, editData)
}

export const recoverRpaPassword = (id, client_id) => {
  return post(`/api/rpa/${client_id}/${id}/recover`)
}

/* DASHBOARD */
export const createPanel = (module, name) => {
  return post(`/api/module/${module}/dashboard`, { name })
}

export const removePanel = (module, id) => {
  return remove(`/api/module/${module}/dashboard/${id}`)
}

export const removeDashboardWidget = (module, dashboardId, widgetId) => {
  return remove(`/api/module/${module}/dashboard/${dashboardId}/${widgetId}`)
}

export const renamePanel = (module, id, name) => {
  return patch(`/api/module/${module}/dashboard/${id}`, { name })
}

export const assignPanelToClient = (module, id) => {
  return post(`/api/module/${module}/dashboard/${id}`)
}

export const updateDashboardWidgets = (widgets, module, id) => {
  return put(`/api/module/${module}/dashboard/${id}`, {
    widgets: widgets
  })
}

export const postEvaluationProfile = data => {
  return post(`/api/evaluation_profiles`, data)
}

export const patchEvaluationProfile = (id, data) => {
  return patch(`/api/evaluation_profiles/${id}`, data)
}

export const postJobOffer = data => {
  return post(`/api/job_offers`, data)
}

export const patchJobOffer = (id, data) => {
  return patch(`/api/job_offers/${id}`, data)
}

export const assignJobOfferToCandidates = (data) => {
  return post(`/api/job_offer/candidates_assign`, data)
}

export const getApplicationsByCandidate = candidateId => {
  return get(`/api/job-offer-application/candidate/${candidateId}`)
}

export const updateApplicationStatusFunnelByCandidature = data => {
  return post(`/api/job-offer-application/candidate/update_status_funnel`, data)
}

export const addJobOfferApplicationRead = data => {
  return post(`/api/job-offer-application-reads/add`, data)
}

export const removeJobOfferApplicationRead = data => {
  return post(`/api/job-offer-application-reads/remove`, data)
}

export const addJobOfferApplicationFav = data => {
  return post(`/api/job-offer-application-favs/add`, data)
}

export const removeJobOfferApplicationFav = data => {
  return post(`/api/job-offer-application-favs/remove`, data)
}

export const changeTagsPerson = ({ ids, tags, module = MODULES.ID_TALENT_ACQUISITION }) => {
  eventTracker.track('ChangeTagsPerson', {
    id: ids,
    tags: tags,
    module: module
  })

  return post(`/api/module/${module}/tags/person/change`, { ids, tags })
}

/* TEMPLATES REQUEST MANAGER */
export const createTemplate = data => {
  return post(`/api/vacancy/selection`, data)
}

/* ADD ASSIGN PROCESS */
export const assignProcess = ({ vacancy_id, candidature_id }) => {
  return patch(`/api/vacancy/selection/${vacancy_id}/add`, { candidature_id: candidature_id })
}

export const changeStateVacancyRequest = (data, process_id, state_id) => {
  return put(`/api/vacancy/selection/${process_id}/${state_id}`, data)
}

/* UPDATE VACANCY REQUEST */
export const updateVacancyRequest = data => {
  return patch(`/api/vacancy/selection/${data.id}`, {
    vacancies: data.vacancies
  })
}

export const postVacancyRequest = data => {
  return post(`/api/vacancy/candidature/change-status`, data)
}

export const deletePerson = personId => {
  return remove(`/api/person/${personId}`)
}

export const getHtmlFromTxt = file => {
  return post(`/api/file_converter/txt`, { file })
}
